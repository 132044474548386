export enum AnalyticsEvents {
    // Auth Events
    anon_to_registered = 'anon_to_registered', // When an user currently anonymous creates a registered account

    // Teacher usage - create
    create_first_class = 'create_first_class',
    create_class = 'create_class',
    create_pupil = 'create_pupil',
    create_librarybook = 'create_librarybook',

    // Teacher usage  - delete
    delete_class = 'delete_class',
    delete_pupil = 'delete_pupil',

    // Invitations and sharing
    create_teacher_invite = 'create_teacher_invite',
    create_parent_invite = 'create_parent_invite',
    redeem_teacher_invite = 'redeem_teacher_invite',
    redeem_parent_invite = 'redeem_parent_invite',

    // Book reading activity
    issue_book = 'issue_book',
    complete_book = 'complete_book',
    return_book = 'return_book',

    // Functionality usage events
    scan_barcode = 'scan_barcode',
    scan_qr = 'scan_qr',
    fetch_bookdetails = 'fetch_bookdetails',
    take_cover_photo = 'take_cover_photo',
    barcode_scan_multipick = 'barcode_scan_multipick',
    convert_localbook_to_librarybook = 'convert_localbook_to_librarybook',
    issue_recommended_book = 'issue_recommended_book',
    issue_scanned_book = 'issue_scanned_book',

    // Server triggers
    upload_ext_url = 'upload_ext_url',

    // Firebase functions
    fn_update_user_claims = 'fn_update_user_claims',
    fn_get_user_entitlements = 'fn_get_user_entitlements',
    fn_delete_pupil = 'fn_delete_pupil',
    fn_delete_class = 'fn_delete_class',
    fn_create_user_token = 'fn_create_user_token',
    fn_create_class_token = 'fn_create_class_token',
    fn_create_pupil_token = 'fn_create_pupil_token',
    fn_redeem_class_invite = 'fn_redeem_class_invite',
    fn_redeem_parent_invite = 'fn_redeem_parent_invite',
    fn_merge_account = 'fn_merge_account',
    fn_redeem_invite = 'fn_redeem_invite',
    fn_leave_group = 'fn_leave_group',
    fn_retrieve_invite_token = 'fn_retrieve_invite_token',

    // Cover Photo
    cover_photo_camera = 'cover_photo_camera',
    cover_photo_upload = 'cover_photo_upload',

    // External API
    ext_fetch_bookdetails = 'ext_fetch_bookdetails',
    ext_fetch_bookdetails_google = 'ext_fetch_bookdetails_google',
    ext_fetch_bookdetails_openlibrary = 'ext_fetch_bookdetails_openlibrary',
}

export enum AnalyticsIssueBookSource {
    class_library = 'library',
    local = 'local'
}

export enum AnalyticsBookDetailsOutcome {
    undefined = 'undefined',
    error = 'error',
    notfound = 'notfound',
    found = 'found',
}

export enum AnalyticsCameraOutcome {
    undefined = 'undefined',
    error = 'error',
    denied = 'denied',
    success = 'success',
    invalid = 'invalid',
    empty = 'empty',
    no_camera = 'no_camera'
}

export enum AnalyticsUploadUrlOutcome {
    undefined = 'undefined',
    error = 'error',
    uploaded = 'uploaded'
}

export enum AnalyticsFunctionOutcome {
    undefined = 'undefined',
    success = 'success',
    error = 'error',
    timeout = 'timeout'
}

export enum AnalyticsIssueBookMethod {
    pupil_page_swipe = 'pupil_page_swipe',
    issuebook_page = 'issuebook_page'
}




