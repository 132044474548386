import { Injectable } from '@angular/core';
import { FirebaseService, IAuthHooksService, UiService } from 'ionic-firebase-auth';
import { User } from '@firebase/auth-types';
import { ClassQuery } from '../state';
import { FirebaseFunctionsService } from './firebase-functions.service';

interface MergeContext {
    token: string;
}

@Injectable({ providedIn: 'root' })
export class MergeUserService implements IAuthHooksService {

    constructor(
        private classQuery: ClassQuery,
        private ui: UiService,
        private fns: FirebaseFunctionsService,
        private fire: FirebaseService
    ) { }

    async prepareMergeSource(sourceUser: User): Promise<MergeContext | null> {
        if (this.classQuery.getCount() > 0
            && sourceUser.isAnonymous
            && await this.ui.yesNoAlert('service.mergeUser.confirmMerge')) {

            const loading = await this.ui.createLoading('service.mergeUser.preparing');
            try {
                await loading.present();
                return await this.fns.createUserAccountToken();
            } catch (error) {
                this.fire.recordException(error);
            } finally {
                loading.dismiss();
            }
        }
        return null;
    }

    async applyMergeToTarget(targetUser: User, context: MergeContext | null) {
        if (context?.token) {
            const loading = await this.ui.createLoading('service.mergeUser.preparing');
            try {
                await loading.present();
                await this.fns.mergeUserAccount({ token: context.token });
            } catch (error) {
                this.fire.recordException(error);
            } finally {
                loading.dismiss();
            }
        }
    }

}
