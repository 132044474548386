import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CollectionConfig, FireAuthState, initialAuthState } from 'akita-ng-fire';
import { Profile } from 'src/app/types';

export interface SessionState extends FireAuthState<Profile> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
@CollectionConfig({ path: 'users' })
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(initialAuthState);
  }

}
