import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ID, LibraryBook } from '@crokerltd/readtrack-shared';
import { RouterQueryHelper } from '../services/router-query.service';
import { AbstractFactoryService, Services } from './abstract-factory';
import { ClassQuery } from './class.query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryService } from './library.service';
import { LibraryQuery } from './library.query';
import { LibraryStore } from './library.store';
import { FirebaseService } from 'ionic-firebase-auth';

@Injectable({ providedIn: 'root' })
export class LibraryFactoryService extends AbstractFactoryService<LibraryBook, LibraryService, LibraryQuery> {

    constructor(
        private classQuery: ClassQuery,
        private routerQuery: RouterQueryHelper,
        private angularFireStore: AngularFirestore,
        fire: FirebaseService
    ) {
        super(fire, classQuery.selectActive().pipe(map(cls => cls?.id)), true);
    }

    protected async serviceConstructor(classid: ID): Promise<Services<LibraryBook, LibraryService, LibraryQuery>> {
        const store = new LibraryStore(classid);
        const query = new LibraryQuery(store);
        const service = new LibraryService(this.fire, classid, store, this.routerQuery, this.angularFireStore);
        return { store, query, service };
    }

    async getActivePath(): Promise<ID | null> {
        return this.classQuery.getActiveId() || null;
    }

    selectActivePath(): Observable<ID | null> {
        return this.classQuery.selectActiveId().pipe(map(i => i || null));
    }

}
