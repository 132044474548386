import { PupilStore } from './pupil.store';
import { AbstractQuery } from './abstract-query';
import { Pupil } from '@crokerltd/readtrack-shared';

export class PupilQuery extends AbstractQuery<Pupil> {

  constructor(
    store: PupilStore,
  ) {
    super(store);
  }

}
