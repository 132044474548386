import { ID, ReadingRecord, Book, IssueBook } from '@crokerltd/readtrack-shared';
import { CollectionConfig } from 'akita-ng-fire';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { RouterQueryHelper, RouterKey } from '../services/router-query.service';
import { RecordsStore } from './records.store';
import { AbstractService, UpdateFn } from './abstract-service';
import { FirebaseService } from 'ionic-firebase-auth';

@CollectionConfig({ path: 'classes/:classid/pupils/:pupilid/records' })
export class RecordsService extends AbstractService<ReadingRecord> {

  sync(classid: ID, pupilid: ID): Observable<DocumentChangeAction<ReadingRecord>[]> {
    return this.syncCollection(`classes/${classid}/pupils/${pupilid}/records`);
  }

  constructor(
    fire: FirebaseService,
    private readonly classid: ID,
    private readonly pupilid: ID,
    private recordsStore: RecordsStore,
    private routerQuery: RouterQueryHelper,
    angularFireStore: AngularFirestore
  ) {
    super(fire, recordsStore, angularFireStore);
    this.syncSub = this.syncWithRetry(undefined, classid, pupilid).subscribe(
      () => { },
      error => {
        this.fire.recordException(error);
      }
    );

    this.activeSub = this.routerQuery.select(RouterKey.READINGRECORD).subscribe(id => {
      try {
        this.setActive(id)
      } catch (error) {
        this.fire.recordException(error);
      }
    });
  }

  getParents(): { classid: ID, pupilid: ID } {
    return {
      classid: this.classid,
      pupilid: this.pupilid
    };
  }

  async addEntity(newRecord: Partial<ReadingRecord>): Promise<ID> {
    return await this.add(newRecord, { params: this.getParents() });
  }


  async issueBook(book: IssueBook) {
    await this.addEntity({ ...book, issueDate: new Date().toISOString() });
  }

  async completeBook(recordid: ID) {
    const updatedRecord: Partial<ReadingRecord> = {
      completeDate: new Date().toISOString()
    };
    await this.updateEntity(recordid, updatedRecord);
  }

  async returnBook(recordid: ID) {
    const updatedRecord: UpdateFn<ReadingRecord> = (record: ReadingRecord | undefined) => {
      const nowString = new Date().toISOString();
      return {
        completeDate: (undefined === record?.completeDate) ? nowString : record?.completeDate,
        returnDate: nowString
      };
    };
    await this.updateEntity(recordid, updatedRecord);
  }

  async addToHistory(book: Book & Partial<ReadingRecord>) {
    const nowString = new Date().toISOString();
    await this.addEntity({ ...book, issueDate: nowString, completeDate: nowString, returnDate: nowString });
  }

}
