import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
}

import { persistState } from '@datorama/akita';

const storage = persistState({ persistOnDestroy: false });

const providers = [{ provide: 'persistStorage', useValue: storage }];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err)); // tslint:disable-line no-console

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
