import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface EntitlementState {
    token?: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'entitlements', resettable: true })
export class EntitlementStore extends Store<EntitlementState> {

    constructor() {
        super({});
    }

}
