// Akita
import { CollectionConfig } from 'akita-ng-fire';

// Rxjs
import { tap, switchMap, take, share, catchError } from 'rxjs/operators';
import { Observable, Subscription, of, combineLatest } from 'rxjs';

// Angular
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, QueryFn } from '@angular/fire/firestore';

// Mal
import { AuthProcessService, FirebaseService } from 'ionic-firebase-auth';

// Local
import { ClassGroup, ClassRole, ID } from '@crokerltd/readtrack-shared';
import { RouterQueryHelper, RouterKey } from '../services/router-query.service';
import { ClassStore } from './class.store';
import { AbstractService } from './abstract-service';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { ClassQuery } from './class.query';
import { AnalyticsEvents } from '../types';

export type UpdateClassFn = (cls: ClassGroup | undefined) => Partial<ClassGroup>;

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'classes' })
export class ClassService extends AbstractService<ClassGroup> {

  syncSub?: Subscription;
  activeSub?: Subscription;

  sync(uid: string): Observable<DocumentChangeAction<ClassGroup>[]> {
    const classQueryFn: QueryFn = ref => ref.where(`teachers.${uid}.role`, '>', "''");  // tslint:disable-line quotemark
    return this.syncCollection(classQueryFn).pipe(
      share(),
      catchError(error => {
        this.fire.recordException(error);
        return of([]);
      }));
  }

  constructor(
    classStore: ClassStore,
    private aps: AuthProcessService,
    private routerQuery: RouterQueryHelper,
    angularFireStore: AngularFirestore,
    private fns: FirebaseFunctionsService,
    private classQuery: ClassQuery,
    fire: FirebaseService,
  ) {
    super(fire, classStore, angularFireStore);
    this.sync = this.sync.bind(this);

    this.syncSub = this.aps.user$
      .pipe(
        tap(user => this.fire.addLogMessage(`ClassService syncSub; uid=${user?.uid}`)),
        tap(_ => this.reset()),
        switchMap(user => (user) ? this.sync(user.uid) : of([]))
      ).subscribe();

    this.activeSub = combineLatest([this.aps.user$, this.routerQuery.select(RouterKey.CLASS)])
      .subscribe(([user, id]) => {
        this.fire.addLogMessage(`ClassService setting active; uid=${user?.uid}, classid=${id}`);
        if (user) {
          this.setActive(id);
        } else {
          this.setActive(null);
        }
      });
  }

  async addEntity(newClass: Partial<ClassGroup>) {
    const user = await this.aps.user$.pipe(take(1)).toPromise();
    if (!user?.uid) {
      throw new Error('Cannot add a class if user is null');
    }
    if (0 === this.classQuery.getAll({ filterBy: item => item.teachers[user.uid].role === ClassRole.owner }).length) {
      this.fire.logEvent(AnalyticsEvents.create_first_class);
    }
    this.fire.logEvent(AnalyticsEvents.create_class);
    if (!newClass.teachers) {
      newClass.teachers = {};
    }
    newClass.teachers[user.uid] = {
      role: ClassRole.owner,
      name: user.displayName
    };
    return await this.add(newClass);
  }

  getParents(): {} {
    return {};
  }

  async deleteClass(classid: ID) {
    this.fire.logEvent(AnalyticsEvents.delete_class, { classid });
    return await this.fns.deleteClass({ classid });
  }

}
