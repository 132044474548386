import { isLibraryRecord, IssueBook, ReadingRecord } from '@crokerltd/readtrack-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractQuery } from './abstract-query';
import { RecordsStore } from './records.store';

export class RecordsQuery extends AbstractQuery<ReadingRecord> {

  constructor(
    store: RecordsStore
  ) {
    super(store);
  }

  getIncludesBook(book: IssueBook): boolean {
    const dups: ReadingRecord[] = [];
    if (book.libraryID) {
      dups.push(...this.getAll({ filterBy: rec => isLibraryRecord(rec) && rec.libraryID === book.libraryID }));
    } else {
      dups.push(...this.getAll({ filterBy: rec => rec.isbn === book.isbn }));
    }
    return dups.length > 0;
  }

  getRequiresAction(): ReadingRecord[] {
    return this.getAll({ filterBy: rec => undefined !== rec.completeDate && undefined === rec.returnDate });
  }

  selectRequiresAction(): Observable<ReadingRecord[]> {
    return this.selectAll({ filterBy: rec => undefined !== rec.completeDate && undefined === rec.returnDate });
  }

  selectAnyRequiresAction(): Observable<boolean> {
    return this.selectRequiresAction().pipe(map(list => list.length > 0));
  }

}
