import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Person } from '@crokerltd/readtrack-shared';

type LinkType = any[] | string | undefined;

@Component({
    selector: 'app-person-listitem',
    templateUrl: 'person-listitem.component.html',
    styleUrls: ['person-listitem.component.scss']
})
export class PersonListItemComponent {

    @Input()
    get routerLink(): LinkType { return this._routerLink; }
    set routerLink(val: LinkType) {
        this._routerLink = val;
        if (val) {
            this.isButton = true;
        }
    }
    private _routerLink: LinkType = undefined;

    @Input()
    isButton: boolean = false;

    @Input()
    person?: Person;

    @Input()
    showBadge?: boolean = false;

    @Output()
    personClick: EventEmitter<Person> = new EventEmitter<Person>();

    doClick() {
        this.personClick.emit(this.person);
    }

}
