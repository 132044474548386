import { ID } from '@crokerltd/readtrack-shared';
import { UserInfo } from '@firebase/auth-types';

export enum AppUserType {
    parent = 'parent',
    teacher = 'teacher'
}

export interface Profile extends UserInfo {
    launchClass: ID | null;
    userType?: AppUserType;
}

export function createSessionState(): Partial<Profile> {
    return {
        launchClass: null
    };
}
