import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { ID } from '@crokerltd/readtrack-shared';
import { CollectionService } from 'akita-ng-fire';
import { Observable, Subscription, throwError, timer } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AbstractEntityState, AbstractStore } from './abstract-store';
import { FirebaseService } from 'ionic-firebase-auth';

export type UpdateFn<T> = (pupil: T) => Partial<T>;

export abstract class AbstractService<T> extends CollectionService<AbstractEntityState<T>> {

    protected syncSub?: Subscription;
    protected activeSub?: Subscription;

    constructor(
        protected fire: FirebaseService,
        protected entityStore: AbstractStore<T>,
        angularFirestore: AngularFirestore
    ) {
        super(entityStore, undefined, angularFirestore);
        this.syncWithRetry = this.syncWithRetry.bind(this);
    }

    protected abstract getParents(): { [key: string]: ID };
    protected abstract sync(...args: string[]): Observable<DocumentChangeAction<T>[]>;

    reset() {
        this.entityStore.reset();
    }

    syncWithRetry(delay: number = 10, ...args: string[]): Observable<DocumentChangeAction<T>[]> {
        return this.sync(...args).pipe(
            catchError(error => {
                if (delay <= 2000 && error.name === 'FirebaseError' && error.code === 'permission-denied') {
                    console.log(`Permission denied ${args} - trying again in ${delay}ms`);
                    return timer(delay).pipe(take(1), switchMap(() => this.syncWithRetry(delay * 2, ...args)));
                }
                return throwError(error);
            })
        );
    }

    async destroy() {
        const storeName = this.entityStore.storeName;
        if (this.syncSub) { this.syncSub.unsubscribe(); }
        if (this.activeSub) { this.activeSub.unsubscribe(); }
        // this.entityStore.destroy();
        // This doesn't seem to do what it should... after lots of headscrating
        // an akita issue raised https://github.com/datorama/akita/issues/602
        // await this.persistStorage.clearStore(this.store.storeName);
    }

    setActive(id?: ID | null) {
        this.entityStore.setActive(id || null);
    }

    abstract addEntity(newEntity: Partial<T>): Promise<ID>;

    async updateEntity(id: ID, updateOrFn: UpdateFn<T> | Partial<T>) {
        if ('function' === typeof updateOrFn) {
            await this.update(id, updateOrFn, { params: this.getParents() });
        } else {
            await this.update(id, updateOrFn, { params: this.getParents() });
        }
    }

    async removeEntity(id: ID) {
        return await this.remove(id, { params: this.getParents() });
    }

}
