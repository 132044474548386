import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from 'ionic-firebase-auth';
import { Book, LibraryBook } from '@crokerltd/readtrack-shared';
import { BookModalResult } from './bookmodal.page';

export interface BookModalOptions {
  forceAddToLibrary?: boolean;
  updateCaption?: string;
  defaultStage?: number;
}

@Injectable({ providedIn: 'root' })
export class BookModalController {

  constructor(
    private modalController: ModalController,
    private fire: FirebaseService
  ) { }

  presentModal(
    book?: Book | LibraryBook | null,
    options: BookModalOptions = {},
  ): Promise<BookModalResult | null> {
    return new Promise<BookModalResult>(async resolve => {
      this.fire.addLogMessage('Launching bookmodal dialog');
      const { BookModalPage } = await import('./bookmodal.page');
      const modal = await this.modalController.create({
        component: BookModalPage,
        cssClass: 'book-modal-dialog',
        componentProps: {
          book: book || undefined,
          ...options
        }
      });
      modal.onDidDismiss().then(async modaldata => {
        this.fire.addLogMessage('Bookmodal dialog dismissed');
        resolve((modaldata.data as BookModalResult) || null);
      });
      await modal.present();
    });
  }

}
