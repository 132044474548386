import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { EntitlementState, EntitlementStore } from './entitlement.store';
import { EntitlementTokenDecode, Entitlements, ID } from '@crokerltd/readtrack-shared';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CacheMap } from '../utils/CacheMap';
import jwt_decode from 'jwt-decode';

function entitlementIsValid(decoded: EntitlementTokenDecode, options: { audience?: string } = {}): boolean {
  if (decoded) {
    const isExpValid = !decoded.exp || decoded.exp >= Math.floor(Date.now() / 1000);
    const isAudValid = !options.audience || decoded.aud === options.audience;
    return isExpValid && isAudValid;
  } else {
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class EntitlementQuery extends Query<EntitlementState> {

  private decodeCache: CacheMap<string, EntitlementTokenDecode | null> = new CacheMap<string, EntitlementTokenDecode | null>();

  constructor(
    entStore: EntitlementStore
  ) {
    super(entStore);
    this.decodeToken = this.decodeToken.bind(this);
  }

  decodeToken(token?: string | EntitlementState): EntitlementTokenDecode | null {
    const tokenString = ('string' === typeof token) ? token : token?.token;
    const decoded = tokenString ? this.decodeCache.get(tokenString, () => jwt_decode(tokenString) as EntitlementTokenDecode) : null;
    if (!decoded) {
      return null;
    } else if (entitlementIsValid(decoded)) {
      return decoded;
    } else {
      console.warn('Token is invalid, expired, or from another user - discarding');
      return null;
    }
  }

  selectHasEntitlement(sub: ID, entitlement: Entitlements): Observable<boolean> {
    return this.select()
      .pipe(
        distinctUntilChanged(),
        map(this.decodeToken),
        map(decode => decode ? undefined !== decode.grants.find(item => sub === item.sub && item.grants.includes(entitlement)) : false)
      );
  }

}

