import { Injectable } from '@angular/core';
import { AngularFireFunctions, } from '@angular/fire/functions';
import { PerformanceService, RemoteConfigService } from 'ionic-firebase-auth';
import {
    CreateClassTokenRequest,
    CreateClassTokenResponse,
    CreatePupilTokenRequest,
    CreatePupilTokenResponse,
    CreateUserAccountTokenRequest,
    CreateUserAccountTokenRespose,
    DeleteClassRequest,
    DeleteClassResponse,
    DeletePupilRequest,
    DeletePupilResponse,
    GetUserEntitlementsRequest,
    GetUserEntitlementsResponse,
    LeaveGroupRequest,
    LeaveGroupResponse,
    MergeUserAccountRequest,
    MergeUserAccountRespomse,
    RedeemClassInviteRequest,
    RedeemClassInviteResponse,
    RedeemParentInviteRequest,
    RedeemParentInviteResponse,
    RetrieveInviteTokenRequest,
    RetrieveInviteTokenResponse,
    UpdateUserClaimsResponse,
} from '@crokerltd/readtrack-shared';
import { catchError, timeout } from 'rxjs/operators';
import { throwError, TimeoutError } from 'rxjs';
import { AnalyticsEvents, AnalyticsFunctionOutcome } from '../types';
import { IPerformanceTrace } from 'ionic-firebase-auth';

interface FnConfig {
    name: string;
    performanceEvent?: AnalyticsEvents;
    timeoutMs?: number;
}

@Injectable({ providedIn: 'root' })
export class FirebaseFunctionsService {

    constructor(
        private aff: AngularFireFunctions,
        private performance: PerformanceService,
        private remoteConfig: RemoteConfigService
    ) { }

    private async callFunction<In, Out>(config: FnConfig, input: In): Promise<Out> {
        const timeoutMs = config.timeoutMs ? config.timeoutMs : await this.remoteConfig.getNumber('timeoutFunction');
        const fn = this.aff.httpsCallable<In, Out>(config.name);
        let trace: IPerformanceTrace | undefined;
        let outcome: AnalyticsFunctionOutcome = AnalyticsFunctionOutcome.undefined;
        if (config.performanceEvent) {
            trace = await this.performance.createTrace(config.performanceEvent);
            trace.start();
        }
        try {
            const response = await fn(input)
                .pipe(
                    timeout(timeoutMs),
                    catchError(error => {
                        if (error instanceof TimeoutError) {
                            outcome = AnalyticsFunctionOutcome.timeout;
                        } else {
                            outcome = AnalyticsFunctionOutcome.error;
                        }
                        return throwError(error);
                    })
                ).toPromise();
            if (trace) {
                trace.putAttribute('outcome', AnalyticsFunctionOutcome.success);
                trace.stop();
            }
            return response;
        } catch (error) {
            if (trace) {
                trace.putAttribute('outcome', outcome);
                trace.stop();
            }
            throw error;
        }
    }

    async updateUserClaims(): Promise<void> {
        await this.callFunction<UpdateUserClaimsResponse, UpdateUserClaimsResponse>
            ({ name: 'updateUserClaims', performanceEvent: AnalyticsEvents.fn_update_user_claims }, {});
    }

    getUserEntitements(): Promise<GetUserEntitlementsResponse> {
        return this.callFunction<GetUserEntitlementsRequest, GetUserEntitlementsResponse>
            ({ name: 'getUserEntitlements', performanceEvent: AnalyticsEvents.fn_get_user_entitlements }, {});
    }

    deletePupil(input: DeletePupilRequest): Promise<DeletePupilResponse> {
        return this.callFunction<DeletePupilRequest, DeletePupilResponse>
            ({ name: 'deletePupil', performanceEvent: AnalyticsEvents.fn_delete_pupil }, input);
    }

    deleteClass(input: DeleteClassRequest): Promise<DeleteClassResponse> {
        return this.callFunction<DeleteClassRequest, DeleteClassResponse>
            ({ name: 'deleteClass', performanceEvent: AnalyticsEvents.fn_delete_class }, input);
    }

    createPupilToken(input: CreatePupilTokenRequest): Promise<CreatePupilTokenResponse> {
        return this.callFunction<CreatePupilTokenRequest, CreatePupilTokenResponse>
            ({ name: 'createPupilToken', performanceEvent: AnalyticsEvents.fn_create_pupil_token }, input);
    }

    redeemParentInvite(input: RedeemParentInviteRequest): Promise<RedeemParentInviteResponse> {
        return this.callFunction<RedeemParentInviteRequest, RedeemParentInviteResponse>
            ({ name: 'redeemParentInvite', performanceEvent: AnalyticsEvents.fn_redeem_parent_invite }, input);
    }

    createClassToken(input: CreateClassTokenRequest): Promise<CreateClassTokenResponse> {
        return this.callFunction<CreateClassTokenRequest, CreateClassTokenResponse>
            ({ name: 'createClassToken', performanceEvent: AnalyticsEvents.fn_create_class_token }, input);
    }

    redeemClassInvite(input: RedeemClassInviteRequest): Promise<RedeemClassInviteResponse> {
        return this.callFunction<RedeemClassInviteRequest, RedeemClassInviteResponse>
            ({ name: 'redeemClassInvite', performanceEvent: AnalyticsEvents.fn_redeem_parent_invite }, input);
    }

    createUserAccountToken(): Promise<CreateUserAccountTokenRespose> {
        return this.callFunction<CreateUserAccountTokenRequest, CreateUserAccountTokenRespose>
            ({ name: 'createUserAccountToken', performanceEvent: AnalyticsEvents.fn_create_user_token }, {});
    }

    retrieveInviteToken(input: RetrieveInviteTokenRequest): Promise<RetrieveInviteTokenResponse> {
        return this.callFunction<RetrieveInviteTokenRequest, RetrieveInviteTokenResponse>
            ({ name: 'retrieveInviteToken', performanceEvent: AnalyticsEvents.fn_retrieve_invite_token }, input);
    }

    mergeUserAccount(input: MergeUserAccountRequest): Promise<MergeUserAccountRespomse> {
        return this.callFunction<MergeUserAccountRequest, MergeUserAccountRespomse>
            ({ name: 'mergeAmergeUserAccountccount', performanceEvent: AnalyticsEvents.fn_merge_account }, input);
    }

    leaveGroup(input: LeaveGroupRequest): Promise<LeaveGroupResponse> {
        return this.callFunction<LeaveGroupRequest, LeaveGroupResponse>
            ({ name: 'leaveGroup', performanceEvent: AnalyticsEvents.fn_leave_group }, input);
    }

}
