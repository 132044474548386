import { Injectable } from '@angular/core';
import { Pupil, ID } from '@crokerltd/readtrack-shared';
import { ChildStore } from './child.store';
import { CollectionConfig } from 'akita-ng-fire';
import { AngularFirestore, DocumentChangeAction, QueryGroupFn } from '@angular/fire/firestore';
import { catchError, share, switchMap, tap } from 'rxjs/operators';
import { Observable, combineLatest, Subscription, of } from 'rxjs';
import { RouterQueryHelper, RouterKey } from '../services/router-query.service';
import { AuthProcessService, FirebaseService } from 'ionic-firebase-auth';
import { AbstractService } from './abstract-service';
import { ChildQuery } from './child.query';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'pupils' })
export class ChildService extends AbstractService<Pupil> {

    syncSub?: Subscription;
    activeSub?: Subscription;

    sync(uid: string): Observable<DocumentChangeAction<Pupil>[]> {
        const childQueryFn: QueryGroupFn = ref => ref.where(`parents.${uid}.role`, '==', "parent"); // tslint:disable-line quotemark
        return this.syncCollectionGroup(childQueryFn).pipe(
            share(),
            catchError(error => {
                this.fire.recordException(error);
                return of([]);
            })
        );
    }

    constructor(
        childStore: ChildStore,
        private childQuery: ChildQuery,
        routerQuery: RouterQueryHelper,
        angularFirestore: AngularFirestore,
        fire: FirebaseService,
        private aps: AuthProcessService
    ) {
        super(fire, childStore, angularFirestore);

        this.syncSub = this.aps.user$
            .pipe(
                tap(user => this.fire.addLogMessage(`ChildService syncSub; uid=${user?.uid}`)),
                tap(_ => this.reset()),
                switchMap(user => (user) ? this.sync(user.uid) : of([]))
            ).subscribe(
                () => { },
                error => {
                    this.fire.recordException(error);
                }
            );

        this.activeSub = combineLatest([this.aps.user$, routerQuery.select(RouterKey.CHILD)])
            .subscribe(([user, id]) => {
                try {

                    if (user) {
                        this.setActive(id);
                    } else {
                        this.setActive(null);
                    }
                } catch (error) {
                    this.fire.recordException(error);
                }
            });
    }

    protected getParents(): { classid: ID } {
        return { classid: this.childQuery.requireActive().classid };
    }

    async addEntity(newEntity: Omit<Pupil, 'id'>): Promise<ID> {
        throw new Error('Shouldnt be here');
    }

}
