import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { ClassGroup } from '@crokerltd/readtrack-shared';
import { AbstractStore } from './abstract-store';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'classes', resettable: true })
export class ClassStore extends AbstractStore<ClassGroup> {

    constructor() {
        super();
    }

}
