import { LibraryBook, ID } from '@crokerltd/readtrack-shared';
import { AbstractStore } from './abstract-store';

export class LibraryStore extends AbstractStore<LibraryBook> {

    constructor(
        readonly classid: ID
    ) {
        super({ name: `class_${classid}_library` });
    }

}
