import { LibraryBook, ID } from '@crokerltd/readtrack-shared';
import { LibraryStore } from './library.store';
import { CollectionConfig } from 'akita-ng-fire';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { RouterQueryHelper, RouterKey } from '../services/router-query.service';
import { AbstractService } from './abstract-service';
import { FirebaseService } from 'ionic-firebase-auth';
import { AnalyticsEvents } from '../types';

export type UpdateLibraryBookFn = (record: LibraryBook) => Partial<LibraryBook>;

@CollectionConfig({ path: 'classes/:classid/books' })
export class LibraryService extends AbstractService<LibraryBook> {

    syncSub?: Subscription;
    activeSub?: Subscription;

    sync(classid: ID): Observable<DocumentChangeAction<LibraryBook>[]> {
        return this.syncCollection(`classes/${classid}/books`);
    }

    constructor(
        fire: FirebaseService,
        readonly classid: ID,
        libraryStore: LibraryStore,
        private routerQuery: RouterQueryHelper,
        angularFireStore: AngularFirestore,
    ) {
        super(fire, libraryStore, angularFireStore);

        this.syncSub = this.syncWithRetry(undefined, classid).subscribe(
            () => { },
            error => {
                this.fire.recordException(error);
            }
        );

        this.activeSub = this.routerQuery.select(RouterKey.BOOK)
            .subscribe(id => {
                try {
                    this.setActive(id)
                } catch (error) {
                    this.fire.recordException(error);
                }
            });
    }

    protected getParents(): { classid: ID } {
        return { classid: this.classid };
    }

    async addEntity(book: Partial<LibraryBook>): Promise<ID> {
        this.fire.logEvent(AnalyticsEvents.create_librarybook);
        const libraryBook: Partial<LibraryBook> = {
            ...book,
            stage: book.stage || null,
            count: book.count || 1
        };
        return await this.add(libraryBook, { params: this.getParents() });
    }

}

