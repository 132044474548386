import { LibraryStore } from './library.store';
import { ISBN, LibraryBook, Book, isLibraryBook } from '@crokerltd/readtrack-shared';
import { AbstractQuery } from './abstract-query';

export class LibraryQuery extends AbstractQuery<LibraryBook> {

  constructor(
    store: LibraryStore
  ) {
    super(store);
  }

  getForISBN(isbn: ISBN): LibraryBook[] {
    return this.getAll({ filterBy: book => (book.isbn === isbn && !book.isDeleted) });
  }

  alreadyContainsBook(book: Book): boolean {
    if (isLibraryBook(book)) {
      return this.hasEntity(book.id);
    } else if (book.isbn) {
      return this.getForISBN(book.isbn).length > 0;
    } else {
      return false;
    }
  }

}
