import { Book, LibraryBook, ISBN, ID, IssueBook, libraryBooktoBook } from '@crokerltd/readtrack-shared';
import { BookModalController, BookModalOptions } from 'src/app/pages/bookmodal/bookmodal.modal';
import { PupilFactoryService, RecordsFactoryService } from '../state';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, Subject, interval, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { getGoogleBookInfo, getOpenLibraryBookInfo } from './bookinfo';
import { IPerformanceTrace, AuthProcessService, UiService, FirebaseService } from 'ionic-firebase-auth';
import { AuthIonicPickerService } from 'ionic-firebase-auth/ionic';
import { LibraryFactoryService } from '../state/library.factory';
import { decode } from 'base64-arraybuffer';
import { AngularFireStorage } from '@angular/fire/storage';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsEvents, AnalyticsBookDetailsOutcome } from '../types';
import { CameraService } from './camera.service';


export function extractStage(title: string, maxStage?: number): number | undefined {
  const reg = /stage +([0-9]+)/i;
  const match = reg.exec(title);
  const stage = (match && undefined !== match[1]) ? parseInt(match[1], 10) : undefined;
  return ((stage && stage > 0) && (undefined === maxStage || stage <= maxStage)) ? stage : undefined;
}

@Injectable({ providedIn: 'root' })
export class BookScanService {

  constructor(
    private ui: UiService,
    private fire: FirebaseService,
    private lfs: LibraryFactoryService,
    private pfs: PupilFactoryService,
    private rfs: RecordsFactoryService,
    private aps: AuthProcessService,
    private pickerService: AuthIonicPickerService,
    private bookModalController: BookModalController,
    private cameraService: CameraService,
    private httpClient: HttpClient,
    private storage: AngularFireStorage,
    private translateService: TranslateService
  ) { }

  /**
   * Triggers dialogue for editing a local book (i.e. not one in a library) and updates book in store.
   *
   * @param book Book to be updated (or undefined if a new book)
   * @param options Options object allowing behavior to be changed
   *
   * @returns either updated book object, or if book has been added to library the libraryID
   */
  async editIssuedBook(book?: IssueBook, options: BookModalOptions = {}): Promise<IssueBook | null> {
    const result = await this.bookModalController.presentModal(book, options);
    if (result) {
      if (result.addToLibrary) {
        this.fire.logEvent(AnalyticsEvents.convert_localbook_to_librarybook);
        if (!(await this.lfs.requireActiveQuery()).alreadyContainsBook(result.book)) {
          const libraryID = await this.lfs.addEntity({ ...result.book, stage: result.stage, count: result.count });
          return { ...result.book, libraryID };
        } else if (await this.ui.yesNoAlert('service.bookscan.confirmAsbookAlreadyInLibrary.message')) {
          const libraryID = await this.lfs.addEntity({ ...result.book, stage: result.stage, count: result.count });
          return { ...result.book, libraryID };
        } else {
          return result.book;
        }
      } else {
        return result.book;
      }
    } else {
      return null;
    }
  }

  /**
   * Scans barCode and adds to the pubpil's reading record
   */
  public async scanBookForIssue(): Promise<IssueBook | null> {
    const caption = await this.translateService.get(
      'book.action.issueTo',
      { pupilName: await (await this.pfs.requireActive()).name }
    ).toPromise();
    return await this.scanBookForPupilRecord(caption);
  }

  /**
   * Scans barCode and adds to the pubpil's reading record
   */
  public async scanBookForHistory(): Promise<IssueBook | null> {
    const caption = await this.translateService.get('book.action.addToHistory').toPromise();
    return await this.scanBookForPupilRecord(caption);
  }

  /**
   * Scans a book to add to the pupil's record.
   *
   * Starts with the barcode scanner:
   *   Warns if the pupil has already read the book.
   *   If the book is a library book returns the book record.
   *   Otherwise opens the dialogue to edit a local book with details retrieved (or empty if non retrieved)
   *
   * @param caption The caption to include on the bookModal form (add book, edit book, etc.)
   *
   * @returns A Promise containing the libraryId | the localbook record | null.
   */
  private async scanBookForPupilRecord(caption?: string): Promise<IssueBook | null> {
    const book = await this.scanBook();
    if (book && (await this.rfs.requireActiveQuery()).getIncludesBook(book)) {
      if (!await this.ui.yesNoAlert('service.bookscan.confirmAsPupilHadAlreadyReadBook.message')) {
        return null;
      }
    }
    if (book && book.libraryID) {
      return book;
    } else {
      const pupil = await this.pfs.getActive();
      return await this.editIssuedBook(
        book || undefined,
        {
          updateCaption: caption,
          defaultStage: pupil?.stage || undefined
        });
    }
  }

  public async scanBookToAddToLibrary(): Promise<ID | null> {
    const book = await this.scanBook();
    if (book && book.libraryID) {
      await this.ui.errorAlert('service.bookscan.bookAlreadyInLibrary.message');
      return book.libraryID;
    } else {
      const pupil = await this.pfs.getActive();
      const result = await this.bookModalController.presentModal(book, {
        updateCaption: await this.translateService.get('book.libraryAction.addToLibrary').toPromise(),
        defaultStage: pupil?.stage || undefined,
        forceAddToLibrary: true
      });
      if (result && result.addToLibrary) {
        return await this.lfs.addEntity({ ...result.book, stage: result.stage, count: result.count });
      } else {
        return null;
      }
    }
  }

  /**
   * Scans a barcode using the camera
   * If running on a webBrower in DevMode - offers a pre-canned selection of barcodes via the picker.
   *
   * Atempts to resolve the scanned ISBN in order of precidence:
   *  - If exists in Library (once) return the library ID
   *  - If exists in Library (multiple times) present a picker and return the user selected book (or null if cancelled)
   *  - Otherwsise try and fetch the book details from online libraries using the ISBN
   *
   * @returns  Library ID (if matched) | Book (if retreived) | null (if cancelled)
   */
  private scanBook(): Promise<IssueBook | null> {
    return new Promise<IssueBook | null>(async resolve => {
      try {
        let isbn: string | null = await this.cameraService.scanBarcode();
        if (isbn) {
          const books: LibraryBook[] = (await this.lfs.requireActiveQuery()).getForISBN(isbn);

          if (books.length === 1) {
            // If a single book in library matches, return the matching LibraryID
            resolve({
              ...libraryBooktoBook(books[0]),
              libraryID: books[0].id
            });

          } else if (books.length === 0) {
            // If the book doesn't natch any in the library, search online for matching books
            resolve(await this.fetchBookDetails(isbn));

          } else {
            // If there are multiple-matching books in the library, present the user a picker to select one
            this.fire.logEvent(AnalyticsEvents.barcode_scan_multipick);
            const pickedLibBook = await this.pickerService.picker<LibraryBook>({
              title: 'service.bookscan.multiMatchPicker.title',
              options: books.map(item => ({ text: item.title, value: item })),
              cancelText: 'service.bookscan.multiMatchPicker.createNewButton',
              confirmText: 'service.bookscan.multiMatchPicker.useSelectedButton'
            });
            if (pickedLibBook) {
              resolve({
                ...libraryBooktoBook(pickedLibBook),
                libraryID: pickedLibBook?.id
              });
            } else {
              resolve(null);
            }
          }

        } else {
          // If user cancels scan operation return null
          resolve(null);
        }
      } catch (error) {
        this.fire.recordException(error);
        await this.ui.errorAlert(error.message, { subHeader: 'service.bookscan.unableToReadBarcode.header' });
      }
    });
  }

  /**
   * Fetch book from online services
   *
   * @param isbn ISBN number of book for which to fetch details
   */
  async fetchBookDetails(isbn: ISBN | null, timeout?: number): Promise<Book | null> {
    if (null !== isbn) {
      let outcome: AnalyticsBookDetailsOutcome = AnalyticsBookDetailsOutcome.undefined;
      let book: Book | null = null;
      const loading = await this.ui.createLoading('service.bookscan.fetchingDetails');
      loading.present();
      const trace = await this.fire.createTrace(AnalyticsEvents.ext_fetch_bookdetails);
      trace.start();

      try {
        book = await this.selectBookInfo(isbn, timeout).toPromise();
        if (book) {
          outcome = AnalyticsBookDetailsOutcome.found;
        } else {
          outcome = AnalyticsBookDetailsOutcome.notfound;
        }
      } catch (error) {
        outcome = AnalyticsBookDetailsOutcome.error;
        this.fire.recordException(error);
      } finally {
        trace.putAttribute('outcome', outcome);
        trace.stop();
        this.fire.logEvent(AnalyticsEvents.fetch_bookdetails, { outcome });
      }

      loading.dismiss();
      if (null === book) {
        await this.ui.errorAlert(
          'service.bookscan.fetchISBNNotFound.message',
          {
            subHeader: 'service.bookscan.fetchISBNNotFound.header',
            interpolateParams: { isbn }
          }
        );
      } else {
        outcome = AnalyticsBookDetailsOutcome.found;
      }
      return book;
    }
    return null;
  }

  public async getCoverPhoto(): Promise<string | null> {
    const photo = await this.cameraService.takePhoto();

    // perform upload
    const uid = this.aps.user?.uid;
    if (photo && photo.base64String && uid) {
      const loading = await this.ui.createLoading('service.bookscan.uploadingPhoto');
      loading.present();
      const uploadTrace = await this.fire.createTrace(AnalyticsEvents.cover_photo_upload);
      uploadTrace.start();
      const data = new Blob([new Uint8Array(decode(photo.base64String))], { type: `image/${photo.format}` });
      const filename = `cover_${Date.now()}.${photo.format}`;
      const uploader = this.storage.upload(`uploads/${uid}/${filename}`, data);
      const url = await (await uploader).ref.getDownloadURL();
      uploadTrace.stop();
      await loading.dismiss();
      return url;
    } else {
      return null;
    }
  }

  private selectBookInfo(isbn: ISBN, timeout = 5000): Observable<Book | null> {

    const callExternalService
      = async (
        fn: (httpClient: HttpClient, isbn: ISBN) => Promise<Book | null>,
        eventKey: AnalyticsEvents
      ): Promise<Book | null> => {
        let trace: IPerformanceTrace;
        let result: Book | null = null;
        let outcome: AnalyticsBookDetailsOutcome = AnalyticsBookDetailsOutcome.undefined;
        trace = await this.fire.createTrace(eventKey);
        trace.start();
        try {
          result = await fn(this.httpClient, isbn);
          if (result) {
            outcome = AnalyticsBookDetailsOutcome.found;
          } else {
            outcome = AnalyticsBookDetailsOutcome.notfound;
          }
        } catch (error) {
          outcome = AnalyticsBookDetailsOutcome.error;
        } finally {
          trace.putAttribute('outcome', outcome);
          trace.stop();
          this.fire.logEvent(eventKey, { outcome, isbn });
        }
        return result;
      };

    return new Observable<Book | null>((observer) => {
      let result: Book | null = null;
      const destroy$: Subject<void> = new Subject<void>();
      merge(
        callExternalService(getOpenLibraryBookInfo, AnalyticsEvents.ext_fetch_bookdetails_openlibrary),
        callExternalService(getGoogleBookInfo, AnalyticsEvents.ext_fetch_bookdetails_google),
      ).pipe(
        takeUntil(destroy$),
        takeUntil(interval(timeout)),
        catchError((error, caught) => {
          // TODO We probably want to do more than swallow these errors
          this.fire.recordException(error);
          return of(null);
        })
      ).subscribe(
        source => { // Next
          if (source) {
            const newresult: Book = {
              isbn: result?.isbn || source.isbn,
              title: result?.title || source.title,
              author: result?.author || source.author,
              cover_url: result?.cover_url || source.cover_url
            };
            if (result !== newresult) {
              result = newresult;
              observer.next(result);
              if (result.isbn && result.title && result.author && result.cover_url) {
                destroy$.next();
                destroy$.complete();
              }
            }
          }
        },
        () => { },  // Error
        () => {
          if (!result) {
            observer.next(result);
          }
          observer.complete();
        } // Complete
      );

    });
  }

}
