import { Injectable } from '@angular/core';
import { Pupil } from '@crokerltd/readtrack-shared';
import { AbstractQuery } from './abstract-query';
import { ChildStore } from './child.store';

@Injectable({ providedIn: 'root' })
export class ChildQuery extends AbstractQuery<Pupil> {

  constructor(
    store: ChildStore,
  ) {
    super(store);
  }

}
