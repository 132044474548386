import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SessionState, SessionStore } from './session.store';
import { ID } from '@crokerltd/readtrack-shared';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppUserType } from '../types';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {

  profile$ = this.select('profile');

  constructor(
    sessionStore: SessionStore
  ) {
    super(sessionStore);
  }

  getLaunchClass(): ID | null {
    return this.store.getValue()?.profile?.launchClass || null;
  }

  selectLaunchClass(): Observable<ID | null> {
    return this.select().pipe(map(session => session?.profile?.launchClass || null));
  }

  selectUserType(): Observable<AppUserType | undefined> {
    return this.select().pipe(map(session => session?.profile?.userType));
  }

}

