import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ID } from '@crokerltd/readtrack-shared';
import { AbstractService, UpdateFn } from './abstract-service';
import { AbstractQuery } from './abstract-query';
import { AbstractStore } from './abstract-store';
import { FirebaseService } from 'ionic-firebase-auth';

export interface Services<T, Service, Query> {
    store: AbstractStore<T>;
    service: Service;
    query: Query;
}

export type PathType = string;

export abstract class AbstractFactoryService<
    T,
    Service extends AbstractService<T> = AbstractService<T>,
    Query extends AbstractQuery<T> = AbstractQuery<T>
    > {

    protected cache: Map<PathType, Services<T, Service, Query>> = new Map<PathType, Services<T, Service, Query>>();
    private cache$: BehaviorSubject<Map<PathType, Services<T, Service, Query>>>
        = new BehaviorSubject<Map<PathType, Services<T, Service, Query>>>(this.cache);
    protected get cacheKeys(): string[] {
        return Array.from(this.cache.keys());
    }

    constructor(fire: FirebaseService, parentIds$?: Observable<undefined | ID>, destroyWhenInactive?: boolean);
    constructor(fire: FirebaseService, parentIds$?: Observable<ID[]>);
    constructor(
        protected fire: FirebaseService,
        protected parentIds$?: Observable<undefined | ID | ID[]>,
        destroyWhenInactive: boolean = false
    ) {
        if (parentIds$) {
            parentIds$.pipe(
                distinctUntilChanged()
            ).subscribe(
                async (ids) => {
                    try {
                        if (Array.isArray(ids)) {
                            await Promise.all(ids.map(async id => await this.createServices(id)));
                            const deadCacheIds = this.cacheKeys.filter(key => !ids.includes(key));
                            deadCacheIds.forEach(id => this.destroyServices(id));
                        } else {
                            if (undefined !== ids) {
                                await this.createServices(ids);
                            }
                            if (destroyWhenInactive) {
                                const deadCacheIds = this.cacheKeys.filter(key => !ids);
                                deadCacheIds.forEach(id => this.destroyServices(id));
                            }
                        }
                    } catch (error) {
                        this.fire.recordException(error);
                    }
                }
            );
        }
    }

    selectServices(id: PathType): Observable<Services<T, Service, Query> | null> {
        return this.cache$.pipe(
            map(cache => cache.get(id) || null),
        );
    }

    selectAllServices(): Observable<Services<T, Service, Query>[]> {
        return this.cache$.pipe(
            map(cache => Array.from(cache.values())),
        );
    }

    selectService(id: PathType): Observable<Service | null> {
        return this.selectServices(id).pipe(
            map(services => services?.service || null)
        );
    }

    selectQuery(id: PathType): Observable<Query | null> {
        return this.selectServices(id).pipe(
            map(services => services?.query || null)
        );
    }

    selectAllQueries(): Observable<Query[]> {
        return this.selectAllServices().pipe(
            map(services => services.map(item => item.query))
        );
    }

    selectAllEntitiesFromAllQueries(): Observable<T[]> {
        return this.selectAllQueries().pipe(
            switchMap(queries => combineLatest(queries.map(query => query.selectAll()))),
            map(arrayOfArrays => ([] as T[]).concat(...arrayOfArrays))
        );
    }

    getServices(id: PathType): Promise<Services<T, Service, Query>> {
        return this.selectServices(id).pipe(
            filter(i => !!i),
            map(i => i as Services<T, Service, Query>),
            take(1)
        ).toPromise();
    }

    async getService(id: PathType): Promise<Service> {
        return (await this.getServices(id)).service;
    }

    async getQuery(id: PathType): Promise<Query> {
        return (await this.getServices(id)).query;
    }

    protected abstract serviceConstructor(id: PathType): Promise<Services<T, Service, Query>>;

    async createServices(id: PathType): Promise<Services<T, Service, Query>> {
        let services = this.cache.get(id);
        if (!services) {
            // console.log('CREATING SERVICE ID>', id);
            services = await this.serviceConstructor(id);
            this.cache.set(id, services);
            this.cache$.next(this.cache);
            return services;
        } else {
            return services;
        }
    }

    async destroyAll() {
        return Promise.all(Array.from(this.cache.values()).map(services => services.service.destroy()));
    }

    async destroyServices(id: PathType) {
        const services = this.cache.get(id);
        if (services) {
            await services.service.destroy();
            this.cache.delete(id);
        }
    }

    // Active
    abstract getActivePath(): Promise<PathType | null>;
    abstract selectActivePath(): Observable<PathType | null>;

    async getActiveService(): Promise<Service | null> {
        const id = await this.getActivePath();
        return (id) ? await this.getService(id) : null;
    }

    async requireActiveService(): Promise<Service> {
        const service = await this.getActiveService();
        if (!service) {
            throw new Error('No active element or service');
        }
        return service;
    }

    async getActiveQuery(): Promise<Query | null> {
        const id = await this.getActivePath();
        return (id) ? await this.getQuery(id) : null;
    }

    async requireActiveQuery(): Promise<Query> {
        const query = await this.getActiveQuery();
        if (!query) {
            throw new Error('No active element or query');
        }
        return query;
    }

    selectActiveQuery(): Observable<Query | null> {
        return this.selectActivePath().pipe(
            switchMap(id => id ? this.selectQuery(id) : of(null))
        );
    }

    // Helper shortcuts

    async requireActiveId(): Promise<ID> {
        const query = await this.getActiveQuery();
        if (!query) {
            throw new Error('No active query');
        }
        const id = query.getActiveId();
        if (!id) {
            throw new Error('No active element');
        }
        return id;
    }

    async getActive(): Promise<T | undefined> {
        const query = await this.getActiveQuery();
        if (!query) {
            throw new Error('No active query');
        }
        return query.getActive();
    }

    async requireActive(): Promise<T> {
        const id = await this.getActive();
        if (!id) {
            throw new Error('No active element');
        }
        return id;
    }

    async addEntity(newEntity: Partial<T>): Promise<ID> {
        return await (await this.requireActiveService()).addEntity(newEntity);
    }

    async getEntity(id: ID): Promise<T | undefined> {
        return (await this.requireActiveQuery()).getEntity(id);
    }

    async updateEntity(id: ID, updateOrFn: Partial<T> | UpdateFn<T>): Promise<void> {
        await (await this.requireActiveService()).updateEntity(id, updateOrFn);
    }

    async updateActiveEntity(updateOrFn: Partial<T> | UpdateFn<T>): Promise<void> {
        await (await this.requireActiveService()).updateEntity(await this.requireActiveId(), updateOrFn);
    }

    async removeEntity(id: ID): Promise<void> {
        await (await this.requireActiveService()).removeEntity(id);
    }

    selectLoading(): Observable<boolean> {
        return this.selectActiveQuery().pipe(
            switchMap(query => (query) ? query.selectLoading() : of(true))
        );
    }

    selectActive(): Observable<T | undefined> {
        return this.selectActiveQuery().pipe(
            switchMap(query => (query) ? query.selectActive() : of(undefined))
        );
    }

    selectAll(): Observable<T[]> {
        return this.selectActiveQuery().pipe(
            switchMap(query => (query) ? query.selectAll() : of([]))
        );
    }

}
