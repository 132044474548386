// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Mal
import { AuthSharedModule } from 'ionic-firebase-auth';
import { AuthIonicModule } from 'ionic-firebase-auth/ionic';
import { AuthCapacitorModule } from 'ionic-firebase-auth/capacitor';

// Ionic
import { IonicModule } from '@ionic/angular';

// Translate
import { TranslateModule } from '@ngx-translate/core';

// Components
import { BookListItemComponent } from '../components/book-listitem/book-listitem.component';
import { PersonListItemComponent } from '../components/person-listitem/person-listitem.component';
import { NumberIncComponent } from '../components/numberinc/numberinc.component';

// Directives
import { FirestoreDatePipe } from '../pipes/firestoreDate';

export const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  AuthSharedModule,
  AuthIonicModule,
  AuthCapacitorModule,
  IonicModule
];

export const COMPONENTS = [
  BookListItemComponent,
  PersonListItemComponent,
  NumberIncComponent,
  FirestoreDatePipe
];

@NgModule({
  imports: [
    ...MODULES,
    TranslateModule.forChild(),
    RouterModule
  ],
  declarations: COMPONENTS,
  exports: [
    ...MODULES,
    ...COMPONENTS,
    TranslateModule
  ]
})
export class SharedModule { }

