// Angular
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// Mal
import { AuthSharedModule, AuthProvider, AuthHooksProviderToken } from 'ionic-firebase-auth';

// Capacitor
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { environment } from '../environments/environment';

// Akita
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';

// App
import { AppComponent } from './app.component';
import { AppRoutingModule } from './modules/app-routing.module';
import { SharedModule } from './modules/shared.module';

// Pages
// import { HomePage } from './pages/home/home.page';

import { AngularFireStorageModule } from '@angular/fire/storage';

import { TooltipsModule } from 'ionic4-tooltips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MergeUserService } from './services/mergeUser.service';
import { Products } from '@crokerltd/readtrack-shared';
import { AuthCapacitorModule } from 'ionic-firebase-auth/capacitor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedModule,
    IonicModule.forRoot({
      animated: !environment.e2eAutomation
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AkitaNgRouterStoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AuthCapacitorModule.forRoot(environment),
    AuthSharedModule.forRoot({
      ...environment,
      authUi: {
        toastMessageOnAuthSuccess: false,
        authGuardFallbackURL: '/home',
        guardProtectedRoutesUntilEmailIsVerified: true,
        authGuardVerifyEmailURL: '/auth/verify',
        logoUrl: '/assets/images/logo.png',
        reSignInUrl: '/auth/signin',
        verifyEmailBackground: 'background-girl-reading-1',
        supportedProviders: [AuthProvider.Apple, AuthProvider.Facebook, AuthProvider.Google],
        tosUrl: environment.uri.tosUrl,
        privacyPolicyUrl: environment.uri.privacyUrl,
        guestEnabled: true
      }
    }),
    //  MalIAPModule.forRoot({
    //   validatorUrl: environment.iap.validatorUrl,
    //   products: [
    //     {
    //       id: Products.teachProM,
    //       type: IAPProductTypes.PAID_SUBSCRIPTION,
    //     }
    //   ]
    // }), 
    AngularFireStorageModule,
    TooltipsModule.forRoot()
  ],
  providers: [
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: AuthHooksProviderToken, useClass: MergeUserService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(@Inject(PLATFORM_ID) id: any) {
    console.log('ID>', id);
  }

}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
