// Akita
import { cacheable } from '@datorama/akita';

// Rxjs

// Angular
import { Injectable } from '@angular/core';

// Mal
import { AuthProcessService, FirebaseService } from 'ionic-firebase-auth';

// Local
import { EntitlementStore } from './entitlement.store';
import { Products } from '@crokerltd/readtrack-shared';
// import { IAPurchaseService } from 'ionic-firebase-auth/iap';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';

@Injectable({ providedIn: 'root' })
export class EntitlementService {

  public readonly isPro$: Observable<boolean> = this.aps.selectCurrentUserClaim<boolean>('proTeach').pipe(map(isPro => !!isPro));

  constructor(
    private entsStore: EntitlementStore,
    private aps: AuthProcessService,
    private fire: FirebaseService,
    private fns: FirebaseFunctionsService,
    // private iap: IAPurchaseService
  ) {
    // TeachProM fullfillment
    // this.iap.selectVerified(Products.teachProM).subscribe(async p => {
    //   try {
    //     const hasPro = await this.aps.getCurrentUserClaim<boolean>('proTeach');
    //     if (!hasPro) {
    //       this.fire.addLogMessage(`Processing entitlement for product ${p.id}`);
    //       await this.refresh();
    //       p.finish();
    //     }
    //   } catch (error) {
    //     this.fire.recordException(error);
    //   }
    // });

    // // Refresh when uid changes
    // this.aps.user$.pipe(
    //   distinctUntilChanged((x, y) => y?.uid === x?.uid)
    // ).subscribe(async user => {
    //   try {
    //     if (user) {
    //       await this.getEntitlements();
    //     } else {
    //       this.reset();
    //     }
    //   } catch (error) {
    //     this.fire.recordException(error);
    //   }
    // });
  }

  async refresh() {
    await Promise.all([this.updateUserClaims(), this.getEntitlements()]);
  }

  reset() {
    this.entsStore.reset();
  }

  async updateUserClaims(): Promise<void> {
    await this.fns.updateUserClaims();
    await this.aps.refreshToken();
  }

  async getEntitlements(): Promise<void> {
    const response = await this.fns.getUserEntitements();
    if ('string' === typeof response.token) {
      this.entsStore.update(response);
    }
  }

}
