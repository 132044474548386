import { EntityStore } from '@datorama/akita';
import { CollectionState } from 'akita-ng-fire';

export interface AbstractEntityState<T> extends CollectionState<T> { }

export abstract class AbstractStore<T> extends EntityStore<AbstractEntityState<T>> {

    constructor(
        opts?: { name?: string }
    ) {
        super(undefined, opts);
    }

}
