import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ID } from '@crokerltd/readtrack-shared';
import { RouterQuery } from '@datorama/akita-ng-router-store';

export enum RouterKey {
    CLASS = 'classid',
    BOOK = 'bookid',
    PUPIL = 'pupilid',
    CHILD = 'childid',
    READINGRECORD = 'recordid'
}

@Injectable({ providedIn: 'root' })
export class RouterQueryHelper {

    constructor(
        private routerQuery: RouterQuery
    ) {
    }

    private selectRoutedIds(...param: string[]): Observable<(ID | undefined)[]> {
        return this.routerQuery.selectParams(param).pipe(
            map(result => result as (ID | undefined)[]),
            distinctUntilChanged()
        );
    }

    private getRoutedId(param: string): ID | undefined | null {
        return this.routerQuery.getParams(param);
    }

    select(key: RouterKey, override?: ID): Observable<ID | undefined> {
        if (undefined === override) {
            return this.selectRoutedIds(key).pipe(
                map(([id]) => id),
                distinctUntilChanged()
            );
        } else {
            return of(override);
        }
    }

    get(key: RouterKey, override?: ID): ID | undefined | null {
        return (undefined !== override) ? override : this.getRoutedId(key);
    }

}
