import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ID, ClassGroup } from '@crokerltd/readtrack-shared';
import { ClassModalResult, ClassModalInput } from './classmodal.page';
import { ClassService } from 'src/app/state';
import { UiService, FirebaseService } from 'ionic-firebase-auth';

function isExistingClass(x: any): x is ClassGroup {
  return (undefined !== x && undefined !== x?.id);
}

@Injectable({ providedIn: 'root' })
export class ClassModalController {

  constructor(
    private modalController: ModalController,
    private classService: ClassService,
    private fire: FirebaseService,
    private ui: UiService
  ) { }

  public newClass(): Promise<ID | null> {
    return this.editOrNewClass();
  }

  public editClass(classGroup: ClassGroup): Promise<ID | null> {
    return this.editOrNewClass(classGroup);
  }

  private editOrNewClass(classGroup?: ClassGroup): Promise<ID | null> {
    return new Promise<ID | null>(async resolve => {
      this.fire.addLogMessage('Launching classmodal dialog');
      const { ClassModalPage } = await import('./classmodal.page');
      const componentProps: ClassModalInput = {
        class: classGroup
      };
      const modal = await this.modalController.create({
        component: ClassModalPage,
        componentProps,
        cssClass: 'class-modal-dialog',
        showBackdrop: true,
        swipeToClose: true
      });
      const loading = await this.ui.createLoading();
      modal.onDidDismiss().then(async modaldata => {
        try {
          this.fire.addLogMessage('Classmodal dialog dismissed');
          const result: ClassModalResult = modaldata.data;
          if (isExistingClass(result.class)) {
            loading.present();
            await this.classService.update(result.class.id, result.class);
            loading.dismiss();
            resolve(result.class.id);
          } else if (result.class) {
            loading.present();
            const newClassId = await this.classService.addEntity(result.class);
            loading.dismiss();
            resolve(newClassId);
          } else {
            resolve(null);
          }
        } catch (error) {
          loading.dismiss();
          throw error;
        }
      });
      await modal.present();
    });
  }

}
