import { Pupil, ID } from '@crokerltd/readtrack-shared';
import { AbstractStore } from './abstract-store';

export class PupilStore extends AbstractStore<Pupil> {

    constructor(
        readonly classid: ID
    ) {
        super({ name: `class_${classid}_pupils` });
    }

}
