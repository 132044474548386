import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Book, isLibraryBook } from '@crokerltd/readtrack-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-book-listitem',
    templateUrl: 'book-listitem.component.html',
    styleUrls: ['book-listitem.component.scss']
})
export class BookListItemComponent {

    @Input()
    isButton: boolean = false;

    @Input()
    get book(): Book | undefined { return this.book$.value; }
    set book(val: Book | undefined) { this.book$.next(val); }
    book$: BehaviorSubject<Book | undefined> = new BehaviorSubject<Book | undefined>(undefined);

    get stage$(): Observable<number | null> {
        return this.book$.pipe(map(book => isLibraryBook(book) ? book.stage : null));
    }

    @Input()
    showBadge?: boolean = false;

    @Output()
    bookClick: EventEmitter<Book> = new EventEmitter<Book>();

    doClick() {
        this.bookClick.emit(this.book);
    }

}
