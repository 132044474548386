import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare type ValueType = number | null | undefined;
declare type ValueAccessorHandler = (_: ValueType) => void;

@Component({
  selector: 'app-numberinc',
  templateUrl: 'numberinc.component.html',
  styleUrls: ['numberinc.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberIncComponent),
      multi: true
    }
  ]
})
export class NumberIncComponent implements ControlValueAccessor {

  @Input() textIfNull: string = '';
  @Input() readonly = false;
  @Input() min = 0;
  @Input() max = 20;

  @Input()
  get value(): ValueType {
    return (this._value && this._value > this.min) ? this._value : null;
  }
  set value(val: ValueType) {
    this._value = (val && val >= this.min) ? val : this.min;
    this.valueChange.emit(this.value);
    this.propagateChange(this.value);
  }
  protected _value: ValueType;

  get displayValue(): string {
    if (this.value && this.value > this.min) {
      return this.value.toString();
    } else {
      return this.textIfNull;
    }
  }

  @Output()
  valueChange: EventEmitter<ValueType> = new EventEmitter<ValueType>();

  increase() {
    this.value = Math.min(this.max, (this.value || 0) + 1);
  }

  decrease() {
    this.value = Math.max(this.min, (this.value || 0) - 1);
  }

  propagateChange: ValueAccessorHandler = (_: ValueType) => { };
  propagateTouch: ValueAccessorHandler = (_: ValueType) => { };  // TODO - Not implemented.

  writeValue(value: ValueType) {
    this.value = value;
  }

  registerOnChange(fn: ValueAccessorHandler) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: ValueAccessorHandler) {
    this.propagateTouch = fn;
  }

}
