import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { VerifyEmailGuard, LoggedInGuard } from 'ionic-firebase-auth';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth-pages.module').then(m => m.AuthUIPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('../pages/about/about.page').then(m => m.AboutPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('../pages/info/info.page').then(m => m.InfoPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('../pages/home/home.page').then(m => m.HomePageModule),
    canActivate: [VerifyEmailGuard]
  },
  // {
  //   path: 'joinpro',
  //   loadChildren: () => import('../pages/joinpro/joinpro.page').then(m => m.JoinProPageModule),
  //   canActivate: [LoggedInGuard]
  // },
  {
    path: 'redeem',
    loadChildren: () => import('../pages/redeem-invite/redeem-invite.page').then(m => m.RedeemInvitePageModule)
  },
  {
    path: 'class/:classid/tab',
    loadChildren: () => import('./class.module').then(m => m.ClassModule),
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'class/:classid/pupil/:pupilid',
    loadChildren: () => import('./pupil.module').then(m => m.PupilModule),
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'class/:classid/book/:bookid',
    loadChildren: () => import('./book.module').then(m => m.BookModule),
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'class/:classid',
    pathMatch: 'full',
    redirectTo: 'class/:classid/tab'
  },
  {
    path: 'child/:childid',
    loadChildren: () => import('./parent.module').then(m => m.ParentModule),
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

const extraOptions: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  enableTracing: false,
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, extraOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
