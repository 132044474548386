import { EntityStore } from '@datorama/akita';
import { ReadingRecord } from '@crokerltd/readtrack-shared';
import { CollectionState } from 'akita-ng-fire';

export interface RecordsState extends CollectionState<ReadingRecord> { }

export class RecordsStore extends EntityStore<RecordsState> {

    constructor(
        readonly path: string
    ) {
        super(undefined, { name: `pupil_${path}_records` });
    }

}
