// Angular & Ionic
import { Component, Inject, NgZone } from '@angular/core';
import { MenuController, NavController, Platform } from '@ionic/angular';

// Rxjs
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Capacitor
import { Plugins, StatusBarStyle, Capacitor } from '@capacitor/core';
const { CapacitorFirebaseDynamicLinks } = Plugins;

// Mal
import { RemoteConfigService, AuthProcessService, AuthProvider, FirebaseService, UiService } from 'ionic-firebase-auth';

// Firebase
import { User } from '@firebase/auth-types';

// Local
import { ClassGroup, Pupil } from '@crokerltd/readtrack-shared';
import {
  SessionService, ClassQuery, ChildQuery, ChildService, ClassService, PupilFactoryService,
  RecordsFactoryService, LibraryFactoryService, SessionQuery
} from 'src/app/state';
import { ClassModalController } from 'src/app/pages/classmodal/classmodal.modal';
import { RouterKey, RouterQueryHelper, AuthUIProfilePopoverService, InviteService, CameraService } from 'src/app/services';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { PersistState } from '@datorama/akita';
import { EntitlementService } from './state/entitlement.service';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
// import { IAPurchaseService } from 'ionic-firebase-auth/iap';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public readonly remoteConfig$ = this.remoteConfig.remoteConfig$;
  public readonly enableProSignUp$ = this.remoteConfig$.pipe(map(config => config.enableProSignUp));
  public readonly enableTokenInvite$ = this.remoteConfig$.pipe(map(config => config.enableTokenInvite));
  public readonly enableParents$ = this.remoteConfig$.pipe(map(config => config.enableParents));

  public readonly user$: Observable<User | null> = this.aps.user$;
  public readonly showRegister$: Observable<boolean> = this.aps.canRegister$;
  public readonly showLogin$: Observable<boolean> = this.aps.canSignIn$;

  public readonly showSignOut$: Observable<boolean>
    = combineLatest([this.aps.isAnonymous$, this.aps.canSignOut$])
      .pipe(map(([isAnonymous, canSignOut]) => (!environment.production && isAnonymous) || canSignOut));

  public readonly showInfo: boolean = !environment.production;

  public readonly canEdit$: Observable<boolean> = this.aps.canEdit$;
  public readonly classes$: Observable<ClassGroup[]> = this.classQuery.selectAll();
  public readonly myChildren$: Observable<Pupil[]> = this.childQuery.selectAll();

  public readonly showChildrenMenu$: Observable<boolean> =
    combineLatest([this.user$, this.enableParents$, this.myChildren$])
      .pipe(map(([user, enableParents, children]) => !!user && (children.length > 0 || enableParents)))

  public readonly showClassesMenu$: Observable<boolean> = this.user$.pipe(map(user => !!user));

  public readonly enableAnimations: boolean = !environment.e2eAutomation;

  public readonly privacyUrl = environment.uri.privacyUrl;
  public readonly tosUrl = environment.uri.tosUrl;

  public readonly hasCamera$: Observable<boolean> = this.cameraService.hasCamera$;

  constructor(
    private platform: Platform,
    private classQuery: ClassQuery,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private classModalController: ClassModalController,
    private routerQuery: RouterQueryHelper,
    private childQuery: ChildQuery,
    private childService: ChildService,
    private classService: ClassService,
    private pfs: PupilFactoryService,
    private rfs: RecordsFactoryService,
    private lfs: LibraryFactoryService,
    private aps: AuthProcessService,
    private navController: NavController,
    private entsService: EntitlementService,
    // private iap: IAPurchaseService,
    private remoteConfig: RemoteConfigService,
    private route: ActivatedRoute,
    private profilePopover: AuthUIProfilePopoverService,
    private inviteService: InviteService,
    private ui: UiService,
    private fire: FirebaseService,
    private ngZone: NgZone,
    private menuController: MenuController,
    private cameraService: CameraService,
    @Inject('persistStorage') private persistStorage: PersistState
  ) {
    this.initializeApp();
  }

  menuClose() {
    this.menuController.close();
  }

  async doCreateNewClass() {
    try {
      this.menuClose();
      const id = await this.classModalController.newClass();
      if (id) {
        this.navController.navigateRoot(['/class', id]);
      }
    } catch (error) {
      this.fire.recordException(error);
    }
  }

  /**
   * If the current user has created classes then require/trigger merge
   */
  async doLogin() {
    try {
      this.menuClose();
      await this.navController.navigateForward('/auth/signin');
    } catch (error) {
      this.fire.recordException(error);
    }
  }

  async doSignOut() {
    try {
      this.menuClose();
      await this.aps.signOut();
      await this.navController.navigateRoot('/');
    } catch (error) {
      this.fire.recordException(error);
    }
  }

  async doScanPupilQR() {
      this.menuClose();
    let code = await this.inviteService.scanInviteQRCode();
    if (code) {
      await this.navController.navigateForward('/redeem', { queryParams: { code } })
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      try {
        const { SplashScreen, StatusBar } = Plugins;
        if (Capacitor.isPluginAvailable('StatusBar')) {
          StatusBar.setStyle({ style: StatusBarStyle.Dark });
          StatusBar.show();
        }
        if (Capacitor.isPluginAvailable('SplashScreen')) {
          SplashScreen.hide();
        }
        if (Capacitor.platform !== 'web') {
          CapacitorFirebaseDynamicLinks.addListener('deepLinkOpen', (data: { url: string }) => {
            const slug = data.url.split(environment.uri.web).pop();
            if (slug) {
              this.fire.addLogMessage(`Deeplink slug=${slug}`);
              this.ngZone.run(async () => {
                await this.navController.navigateRoot(slug);
              });
            }
          });
        }
        if (!environment.production && environment.e2eAutomation) {
          this.route.queryParamMap.subscribe(async param => {
            const emulatorCred = param.get('emulator_credential');
            const emulatorRedirect = param.get('emulator_redirect');
            if (emulatorCred && null === this.aps.user) {
              console.warn(`E2E Test Mode: Authenticating using emulator_credential`); // tslint:disable-line no-console
              await this.aps.signInWith(AuthProvider.EmailAndPassword, { credentials: JSON.parse(emulatorCred), skipTosCheck: true });
            }
            if (emulatorRedirect) {
              console.warn(`E2E Test Mode: Redirecting on login to ${emulatorRedirect}`);  // tslint:disable-line no-console
              await this.navController.navigateRoot(emulatorRedirect);
            }
          });
        }
      } catch (error) {
        this.fire.recordException(error);
      }
    });

    /* Populate launchClass */
    combineLatest([this.aps.user$, this.routerQuery.select(RouterKey.CLASS)])
      .subscribe(([user, id]) => {
        try {
          if (id && user) {
            this.sessionService.setLaunchClass(id);
          }
        } catch (error) {
          this.fire.recordException(error);
        }
      });

    this.aps.onSignOut$.subscribe(async () => {
      try {
        await Promise.all([
          this.childService.reset(),
          this.classService.reset()
        ]);
        await Promise.all([
          this.pfs.destroyAll(),
          this.lfs.destroyAll(),
          this.rfs.destroyAll(),
        ]);
        this.persistStorage.clearStore();
      } catch (error) {
        this.fire.recordException(error);
      }
    });

  }

}
