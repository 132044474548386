import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

export class CacheMap<K, T> {
    private _cache = new Map<K, T>();
    private _cache$: Subject<Map<K, T>> = new Subject<Map<K, T>>();
    readonly cache$: Observable<Map<K, T>> = this._cache$.asObservable();

    constructor() {
        this._cache$.next(this._cache);
    }

    set(k: K, v: T) {
        this._cache.set(k, v);
        this._cache$.next(this._cache);
    }

    refresh(k: K, loadFn: (k?: K) => T | undefined) {
        this.get(k, loadFn);
    }

    get(k: K, loadFn?: (k?: K) => T | undefined): T | undefined {
        const cached = this._cache.get(k);
        if (cached) {
            return cached;
        } else if (loadFn) {
            const value = loadFn(k);
            if (undefined !== value) {
                this.set(k, value);
            }
            return value;
        }
        return undefined;
    }

    has(k: K): boolean {
        return this._cache.has(k);
    }

    delete(k: K | K[]) {
        if (Array.isArray(k)) {
            k.forEach(item => this._cache.delete(item));
        } else {
            this._cache.delete(k);
        }
        this._cache$.next(this._cache);
    }

    get keys(): K[] {
        return Array.from(this._cache.keys());
    }

    selectValue(k: K): Observable<T | undefined> {
        return this.cache$.pipe(
            map(cache => cache.get(k)),
            distinctUntilChanged()
        )
    }

    selectAll(): Observable<T[]> {
        return this.cache$.pipe(
            map(cache => Array.from(cache.values()))
        )
    }

}