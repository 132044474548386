import { Injectable } from "@angular/core";
import { EnvConfig } from "src/environments/EnvConfig";
import { environment } from "src/environments/environment";
import { Capacitor, Device, DeviceInfo, PermissionType, Plugins } from '@capacitor/core';
const { Permissions } = Plugins;

export enum CameraStatus {
    denied, ok, no_camera
}

@Injectable({ providedIn: 'root' })
export class DeviceService {

    /**
     * Provice access to the environment file in a mockable way
     */
    getEnv(): EnvConfig {
        return environment;
    }

    /**
     * Returns if the current device is native
     * @returns Capacitor.isNative
     */
    get isNative(): boolean {
        return Capacitor.isNative || false;
    }

    /**
     * Returns information object about device (from Capacitor)
     * @returns DeviceInfo object
     */
    getInfo(): Promise<DeviceInfo> {
        return Device.getInfo();
    }

    /**
     * Indicates if the camera is present and useable.
     * 
     * iOS and android seem to behave differently. Where iOS reports prompt that indicates that the 
     * user will be promoted on usage request. We could this this to provide a message (e.g. why we want camera)
     * but currently don't.
     * 
     * @returns The current status of the camera (ok, denined, no_camera)
     */
    async getCameraStatus(): Promise<CameraStatus> {
        const deviceInfo = await this.getInfo();
        const validPlatform = this.isNative && !deviceInfo.isVirtual && Capacitor.isPluginAvailable('Camera');
        if (validPlatform && Capacitor.platform === 'ios') {
            const cameraPermssions = await Permissions.query({ name: PermissionType.Camera });
            switch (cameraPermssions.state) {
                case 'granted':
                case 'prompt':
                    return CameraStatus.ok;

                default:
                    return CameraStatus.denied;
            }
        } else if (validPlatform) {
            return CameraStatus.ok; // Android seems to default to denies rather than prompt
        }
        return CameraStatus.no_camera;
    }

}