import { AuthUserProvidedConfig } from 'ionic-firebase-auth';
import { LinkConfig } from '@turnoutt/capacitor-firebase-dynamic-links';
import { AuthCapacitorConfig } from 'ionic-firebase-auth/capacitor/interfaces';

export interface EnvConfig extends AuthUserProvidedConfig, AuthCapacitorConfig {
    production: boolean;
    e2eAutomation?: boolean;
    uri: {
        web: string,  // This contains the deeplink recieved from dymanic links
        tosUrl?: string,
        privacyUrl?: string,
        fnBase: string
    };
    dynamicLink: Partial<LinkConfig> & {
        domainUriPrefix: string
    };
    configDefaults: {
        // Feature settings
        enableWelcomeBackToast: boolean,
        enableTargetBooks: boolean,
        enableProSignUp: boolean,
        enableParents: boolean
        // Timeouts
        timeoutLoading: number,
        timeoutFunction: number,
        timeoutInvite: number
        // Debounce UI
        debouceUi: number
        // Defaults
        defaultMaxStage: number
    };
    isbnApi: {
        googleApis: string;
        openLibrary: string;
    };
    iap: {
        validatorUrl: string;
    };
    google: {
        apiKey: string;
    };
}


export const SHARED_DEFAULTS = {
    enableWelcomeBackToast: false,
    enableTargetBooks: false,
    enableProSignUp: false,
    enableParents: false,
    timeoutLoading: 3000,
    timeoutInvite: 10000,
    timeoutFunction: 10000,
    debouceUi: 100,
    defaultMaxStage: 15
};




