import { Injectable } from '@angular/core';
import { CameraPhoto, CameraResultType, CameraSource,  Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { FirebaseService, UiService } from 'ionic-firebase-auth';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { AnalyticsCameraOutcome, AnalyticsEvents } from '../types';
import { ISBN } from '@crokerltd/readtrack-shared/lib';
import { AuthIonicPickerService } from 'ionic-firebase-auth/ionic';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CameraStatus, DeviceService } from './device.service';
const { Camera } = Plugins;

const TEST_BOOK_BARCODES = [
    { text: 'last continent', value: '9780552146142' },
    { text: 'Invalid Barcode', value: '97805146142' },
    { text: 'Emily\'s legs', value: '9780356136868' },
    { text: 'Storm castle', value: '9780199163458' },
    { text: 'A foturne for yoyo', value: '9781408305096' },
    { text: 'Legends', value: '9780006483946' },
    { text: 'Dough Barcode', value: '9781856267625' },
    { text: 'Voyage of the Vikings', value: '9781782953791' },
    { text: 'Lions on the loose', value: '9781782953883' },
    { text: 'James and the peach', value: '9780141365459' },
    { text: 'Hot dog harries', value: '9781408302958' },
    { text: 'Dough DB', value: '9781909487536' }
];



enum CameraError {
    denied, unobtainable, other
}

/**
 * Handles all operations using the devices camera
 */
@Injectable({ providedIn: 'root' })
export class CameraService {

    /**
     * Store the last known camera status
     */
    readonly cameraStatus$: BehaviorSubject<CameraStatus> = new BehaviorSubject<CameraStatus>(CameraStatus.no_camera);

    /**
     * Observable indicating if the application has access to a camera
     */
    readonly hasCamera$: Observable<boolean> = this.cameraStatus$.pipe(
        map(status => status === CameraStatus.ok),
        distinctUntilChanged()
    )

    constructor(
        private scanner: BarcodeScanner,
        private fire: FirebaseService,
        private ui: UiService,
        private pickerService: AuthIonicPickerService,
        private deviceService: DeviceService
    ) {
        this.deviceService.getCameraStatus().then(status => this.cameraStatus$.next(status));
    }



    /**
     * Translate a camera error message into a enum (denied, unobtainable, other)
     * 
     * There seems to be no error code in the error thrown, this parses the message (yuk!) to
     * codify the error. At least it's done in one place.
     * 
     * @param error Error thrown by Camera access request
     * @returns Camera error type
     */
    cameraErrorType(error: any): CameraError {
        switch (error) {
            case 'Access to the camera has been prohibited; please enable it in the Settings app to continue.':
                return CameraError.denied;
            case 'unable to obtain video capture device':
                return CameraError.unobtainable;
            default:
                return CameraError.other;
        }
    }

    /**
     * Scans a QR code, and validates that it is for the current application
     * 
     * @returns The scanned join-code (less the URL prefix), or null if not a valid QR code for the app.
     */
    scanQRCode(): Promise<string | null> {
        return this.scan({
            /**
             * Validates scanned QR URL belongs to application, and remove the url prefix.
             */
            postProcessFn: async (value: string) => {
                if (value.startsWith(environment.dynamicLink.domainUriPrefix)) {
                    return { value: value.split('/').pop() || null, outcome: AnalyticsCameraOutcome.success };
                } else if (value) {
                    return { value, outcome: AnalyticsCameraOutcome.invalid };
                } else {
                    return { value, outcome: AnalyticsCameraOutcome.empty };
                }
            },
            /**
             * If no QR Code is scanned, present the user with a text input to enter the join-code
             */
            fallbackFn: async (reason: AnalyticsCameraOutcome) => {
                let messageKey: string;
                switch (reason) {
                    case AnalyticsCameraOutcome.denied:
                        messageKey = 'cameraDenied';
                        break;
                    case AnalyticsCameraOutcome.no_camera:
                    default:
                        messageKey = 'noCamera';
                        break;
                }
                return await this.ui.textboxInputAlert({ subHeader: `service.barcode.scanQr.${messageKey}`, placeholder: `service.barcode.scanQr.placeholder` }) || null
            },
            scanOptions: {
                formats: 'QR_CODE'
            },
            event: AnalyticsEvents.scan_qr
        });
    }

    /**
     * Scan a barcode
     * 
     * @returns book ISBN number or null if cancelled/invalid
     */
    scanBarcode(): Promise<ISBN | null> {
        return this.scan({
            /**
             *  Mark outcome as empty/success
             */
            postProcessFn: async (text: string) => {
                if (!!text) {
                    return { value: text, outcome: AnalyticsCameraOutcome.success };
                } else {
                    return { value: null, outcome: AnalyticsCameraOutcome.empty };
                }
            },
            /**
             *  If no barcode scanned, then either present the user a text input box for the ISBN,
             *  or if running in dev mode, a picker to allow the book to be selected (for developer testing)
             */
            fallbackFn: async (outcome: AnalyticsCameraOutcome) => {
                let value: string | null = null;
                if (!this.deviceService.getEnv().production) {
                    if (this.deviceService.getEnv().e2eAutomation) {
                        value = await this.ui.textboxInputAlert({ header: 'service.barcode.devOnly.isbnTextHeader' });
                    } else {
                        value = await this.pickerService.picker<string>({ title: 'service.barcode.devOnly.webPickerTitle', options: TEST_BOOK_BARCODES });
                    }
                } else {
                    console.warn('Barcode scanner does not work on this device');
                }
                return value;
            },
            event: AnalyticsEvents.scan_barcode
        });
    }

    /**
     * Uses the phone camera to scan either a barcode or a QR code.
     * 
     * This function does all of the heavy lifting for scanBarcode and ScanQR.
     * It:
     *   - Logs the analytics event, and records performance trace
     *   - Logs any errors
     *   - handles camera acess/non-presence issues
     * 
     * @param options An object containing:
     *   {
     *      event: The analytic event to capture
     *      scanOptions: Options to pass to barcodeScanner service
     *      fallbackFn: Function to invoke if scan is not possible (e.g. launch a textbox)
     *      postProcessFn: Function to invoke on scan result if successful (e.g. validation)
     *   }
     * 
     * @returns Promise with either a string (result) of null (failure/cancelled)
     */
    private async scan(options: {
        event: AnalyticsEvents,
        scanOptions?: BarcodeScannerOptions,
        fallbackFn: (reason: AnalyticsCameraOutcome) => Promise<string | null>,
        postProcessFn: (value: string) => Promise<{ value: string | null, outcome: AnalyticsCameraOutcome }>;
    }): Promise<string | null> {

        let value: string | null = null;
        let outcome: AnalyticsCameraOutcome;
        const trace = await this.fire.createTrace(options.event);
        trace.start();
        try {
            const cameraStatus = await this.deviceService.getCameraStatus();
            if (CameraStatus.ok === cameraStatus) {
                try {
                    const scan = await this.scanner.scan(options.scanOptions);
                    this.cameraStatus$.next(CameraStatus.ok);
                    const valResult = await options.postProcessFn(scan.text);
                    outcome = valResult.outcome;
                    value = valResult.value;
                } catch (error) {
                    switch (this.cameraErrorType(error)) {
                        case CameraError.denied:
                            this.cameraStatus$.next(CameraStatus.denied);
                            outcome = AnalyticsCameraOutcome.denied;
                            value = await options.fallbackFn(outcome);
                            break;
                        case CameraError.unobtainable:
                            this.cameraStatus$.next(CameraStatus.no_camera);
                            outcome = AnalyticsCameraOutcome.no_camera;
                            value = await options.fallbackFn(outcome);
                            break;
                        default:
                            outcome = AnalyticsCameraOutcome.error;
                            this.ui.logError(error);
                    }
                }
            } else if (CameraStatus.denied === cameraStatus) {
                this.cameraStatus$.next(CameraStatus.denied);
                outcome = AnalyticsCameraOutcome.denied;
                value = await options.fallbackFn(outcome);
            } else {
                this.cameraStatus$.next(CameraStatus.no_camera);
                outcome = AnalyticsCameraOutcome.no_camera;
                value = await options.fallbackFn(outcome);
            }

        } catch (error) {
            outcome = AnalyticsCameraOutcome.error;
            value = null;
            this.ui.logError(error);
        }

        trace.putAttribute('outcome', outcome);
        trace.stop();
        this.fire.logEvent(options.event, { outcome });
        return value || null;
    }


    /**
     * Takes a photo using the phone's camera
     * 
     * @returns either the photo or null (if cancelled or permission denied)
     */
    public async takePhoto(): Promise<CameraPhoto | null> {
        const cameraTrace = await this.fire.createTrace(AnalyticsEvents.cover_photo_camera);
        cameraTrace.start();

        let photo: CameraPhoto | undefined;
        let outcome: AnalyticsCameraOutcome = AnalyticsCameraOutcome.undefined;
        const cameraStatus = await this.deviceService.getCameraStatus();
        try {
            if (CameraStatus.ok === cameraStatus) {
                photo = await Camera.getPhoto({
                    resultType: CameraResultType.Base64,
                    source: CameraSource.Prompt,
                    quality: 100,
                    webUseInput: true
                });
                if (photo && photo.base64String) {
                    outcome = AnalyticsCameraOutcome.success;
                } else {
                    outcome = AnalyticsCameraOutcome.empty;
                }
            } else if (CameraStatus.denied == cameraStatus) {
                this.cameraStatus$.next(CameraStatus.denied);
                outcome = AnalyticsCameraOutcome.denied;
            } else {
                this.cameraStatus$.next(CameraStatus.no_camera);
                outcome = AnalyticsCameraOutcome.no_camera;
            }
        } catch (error) {
            outcome = AnalyticsCameraOutcome.error;
            this.fire.recordException(error);
        }

        cameraTrace.putAttribute('outcome', outcome);
        cameraTrace.stop();
        this.fire.logEvent(AnalyticsEvents.cover_photo_camera, { outcome });
        return photo || null;
    }

}
