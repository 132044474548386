import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { AuthDeviceInfoService } from 'ionic-firebase-auth';
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;

function isDate(x: any): x is Date {
    return undefined !== (x as Date).getTime;
}

function isTimestamp(x: any): x is Timestamp {
    return undefined !== (x as Timestamp).toDate;
}

@Pipe({
    name: 'firestoreDate'
})
export class FirestoreDatePipe implements PipeTransform {

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private deviceInfoService: AuthDeviceInfoService,
    ) {
    }

    transform(timestamp: Timestamp | Date | string, format?: string): string | void {
        if ('string' === typeof timestamp) {
            return formatDate(new Date(timestamp), format || this.deviceInfoService.dateFormat, this.locale);
        } else if (isDate(timestamp)) {
            return formatDate(timestamp, format || this.deviceInfoService.dateFormat, this.locale);
        } else if (isTimestamp(timestamp)) {
            return formatDate(timestamp.toDate(), format || this.deviceInfoService.dateFormat, this.locale);
        }
    }

}
