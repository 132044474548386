import { Injectable } from '@angular/core';
import { ClassStore } from './class.store';
import { ClassGroup, Entitlements } from '@crokerltd/readtrack-shared';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { RemoteConfigService } from 'ionic-firebase-auth';
import { AbstractQuery } from './abstract-query';
import { EntitlementQuery } from './entitlement.query';

@Injectable({ providedIn: 'root' })
export class ClassQuery extends AbstractQuery<ClassGroup> {

  constructor(
    store: ClassStore,
    private remoteConfig: RemoteConfigService,
    private entsQuery: EntitlementQuery
  ) {
    super(store);
  }

  selectByName(re: RegExp | string): Observable<ClassGroup[]> {
    return this.selectAll({
      filterBy: item => ('string' === typeof re) ? item.name === re : re.test(item.name)
    });
  }

  getByName(re: RegExp | string): ClassGroup[] {
    return this.getAll({
      filterBy: item => ('string' === typeof re) ? item.name === re : re.test(item.name)
    });
  }

  selectActiveClassName(): Observable<string> {
    return this.selectActive()
      .pipe(map(cg => cg?.name || 'Class'));
  }

  selectActiveMaxStage(): Observable<number> {
    return this.selectActive()
      .pipe(switchMap(cls => (cls?.maxStage) ? of(cls.maxStage) : this.remoteConfig.getNumber('defaultMaxStage')));
  }

  selectActiveIsPro(): Observable<boolean> {
    return this.selectActive().pipe(
      switchMap(cls => cls ? this.entsQuery.selectHasEntitlement(cls.id, Entitlements.pro) : of(false))
    );
  }

}

