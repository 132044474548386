import { Book, ISBN } from '@crokerltd/readtrack-shared';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export async function getGoogleBookInfo(httpClient: HttpClient, isbn: ISBN): Promise<Book | null> {

    const results: { [key: string]: any; }
        = await httpClient.get(`${environment.isbnApi.googleApis}?key=${environment.google.apiKey}&q=isbn:${isbn}`).toPromise();

    if (Array.isArray(results.items) && results.items.length >= 1) {
        const googleResult = results.items[0].volumeInfo;
        const result = {
            isbn,
            title: googleResult.title,
            author: (googleResult.authors) ? googleResult.authors.join(', ') : undefined,
            cover_url: googleResult.imageLinks?.thumbnail || googleResult.imageLinks?.smallThumbnail
        };
        return result;
    } else {
        return null;
    }
}
