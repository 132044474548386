import { Injectable } from '@angular/core';
import { LibraryBook, IssueBook, isLibraryBook, Pupil, recordsIncludeISBN, recordsIncludeLibraryBook, ID, ReadingRecord } from '@crokerltd/readtrack-shared';
import { map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { PupilFactoryService, RecordsFactoryService } from '../state';
import { UpdateFn } from 'src/app/state/abstract-service';
import { LibraryFactoryService } from '../state/library.factory';
import { FirebaseService } from 'ionic-firebase-auth';
import { AnalyticsEvents, AnalyticsIssueBookSource } from '../types';

@Injectable({ providedIn: 'root' })
export class TeacherService {

    constructor(
        private pfs: PupilFactoryService,
        private rfs: RecordsFactoryService,
        private lfs: LibraryFactoryService,
        private fire: FirebaseService
    ) {
        this.recommendNextBooks = this.recommendNextBooks.bind(this);
        this.selectPupilRequiresAction = this.selectPupilRequiresAction.bind(this);
    }

    selectRecommendedBooks(): Observable<LibraryBook[]> {
        return combineLatest([
            this.pfs.selectActive(),
            this.lfs.selectAll(),
            this.rfs.selectAll()
        ]).pipe(
            map(([pupil, books, records]) =>
                (pupil && books && records)
                    ? this.recommendNextBooks(pupil, books, records)
                    : []
            )
        );
    }

    public recommendNextBooks(pupil: Pupil, library: LibraryBook[], pupilRecords: ReadingRecord[]): LibraryBook[] {
        return (undefined !== library && undefined !== pupil)
            ? library.filter(book => (
                !book.isDeleted
                && book.stage === pupil.stage
                && !recordsIncludeLibraryBook(pupilRecords, book.id)
                && (!book.isbn || !recordsIncludeISBN(pupilRecords, book.isbn))
            ))
            : [];
    }

    public selectPupilRequiresAction(pupil: Pupil): Observable<boolean> {
        return this.rfs.selectQuery(pupil.id).pipe(
            switchMap(query => query ? query.selectAnyRequiresAction() : of(false))
        );
    }

    async issueBook(book: IssueBook | LibraryBook) {
        if (isLibraryBook(book)) {
            this.fire.logEvent(
                AnalyticsEvents.issue_book,
                { source: AnalyticsIssueBookSource.class_library, id: book.id, isbn: book.isbn, title: book.title }
            );
            return await (await this.rfs.requireActiveService()).issueBook({ ...book, libraryID: book.id });
            // TODO - Count number of books issued
            // this.bookService.issueBook(book);
        } else {
            this.fire.logEvent(
                AnalyticsEvents.issue_book,
                { source: AnalyticsIssueBookSource.local, isbn: book.isbn, title: book.title }
            );
            return await (await this.rfs.requireActiveService()).issueBook(book);
        }
    }

    async addToHistory(book: IssueBook) {
        return await (await this.rfs.requireActiveService()).addToHistory(book);
        // TODO - Count number of books issued
        // this.bookService.issueBook(book);
    }

    async removeReadingRecord(recordid: ID) {
        await (await this.rfs.requireActiveService()).removeEntity(recordid);
    }

    async completeBook(recordid: ID) {
        this.fire.logEvent(AnalyticsEvents.complete_book, { recordid });
        return await (await this.rfs.requireActiveService()).completeBook(recordid);
    }

    async updateReadingRecord(recordid: ID, updatedRecord: UpdateFn<ReadingRecord> | Partial<ReadingRecord>) {
        return await (await this.rfs.requireActiveService()).updateEntity(recordid, updatedRecord);
    }

    async returnBook(recordid: ID) {
        this.fire.logEvent(AnalyticsEvents.return_book, { recordid });
        return await (await this.rfs.requireActiveService()).returnBook(recordid);
        // TODO - Count number of books issued
        // this.bookService.issueBook(book);
    }

}
