// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvConfig, SHARED_DEFAULTS } from './EnvConfig';

// START: This code is generated using malconfig

export const environment: EnvConfig = {
  production: false,
  configDefaults: {
    ...SHARED_DEFAULTS,
    enableProSignUp: false,
    enableParents: false
  },
  services: {
    firebaseRegion: 'europe-west2'
  },
  firebase: {
    apiKey: 'AIzaSyA3li57QdPrUfHRfLs28s691xljlVq3kxU',
    authDomain: 'readtrack-dev-64a12.firebaseapp.com',
    databaseURL: 'https://readtrack-dev-64a12.firebaseio.com',
    projectId: 'readtrack-dev-64a12',
    storageBucket: 'readtrack-dev-64a12.appspot.com',
    messagingSenderId: '742690746406',
    appId: '1:742690746406:web:1e8baeffe18294350af6e7',
    measurementId: 'G-87ZR2Y0VY0'
  },
  isbnApi: {
    googleApis: 'https://www.googleapis.com/books/v1/volumes',
    openLibrary: 'https://openlibrary.org/api/books'
  },
  uri: {
    web: 'https://readtrack.dev.croker.ltd',
    tosUrl: 'https://readtrack.net/terms-and-conditions/',
    privacyUrl: 'https://readtrack.net/privacy-policy/',
    fnBase: 'https://europe-west2-readtrack-dev-64a12.cloudfunctions.net'
  },
  dynamicLink: {
    domainUriPrefix: 'https://devreadtrack.page.link',
    iosParameters: {
      bundleId: 'ltd.croker.readtrack',
      customScheme: 'readtrack'
    }
  },
  signInWithApple: {
    authUrl: 'http://appleid.apple.com/auth/authorize',
    redirectUrl: 'https://europe-west2-readtrack-dev-64a12.cloudfunctions.net/appleAuthHandler',
    redirectDeekLink: 'https://auth.readtrack.dev.croker.ltd/apple_response_url',
    clientId: 'ltd.croker.dev.readtrack.service'
  },
  iap: {
    validatorUrl: 'https://validator.fovea.cc/v1/validate?appName=ltd.croker.readtrack&apiKey=54133be3-f459-4890-ab33-8a1367c23011'
  },
  google: {
    apiKey: 'AIzaSyBzAhRmiTmgsnqhccMzO2OhRenc7TRJdkU'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
