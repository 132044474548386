import { QueryEntity } from '@datorama/akita';
import { ID } from '@crokerltd/readtrack-shared';
import { AbstractStore, AbstractEntityState } from './abstract-store';

export abstract class AbstractQuery<T> extends QueryEntity<AbstractEntityState<T>> {

  constructor(
    entityStore: AbstractStore<T>,
  ) {
    super(entityStore);
  }

  requireActive(): T {
    const entity = this.getActive();
    if (!entity) {
      throw new Error('no active entity');
    }
    return entity;
  }

  requireActiveId(): ID {
    const entity = this.getActiveId();
    if (!entity) {
      throw new Error('no active entity');
    }
    return entity;
  }

}
