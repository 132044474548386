import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { ClassAccessToken, PupilAccessToken, ClassRole, ID, ReadTrackAccessToken } from '@crokerltd/readtrack-shared';
import { Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { LinkConfig, CapacitorFirebaseDynamicLinksPlugin } from '@turnoutt/capacitor-firebase-dynamic-links';
import { environment } from 'src/environments/environment';
import { FirebaseFunctionsService } from './firebase-functions.service';
import { AnalyticsEvents } from '../types';
import { FirebaseService, UiService } from 'ionic-firebase-auth';
import { CameraService } from './camera.service';

// TODO - This is a horrible hack to get type definitions
const CapacitorFirebaseDynamicLinks = Capacitor.Plugins?.CapacitorFirebaseDynamicLinks as CapacitorFirebaseDynamicLinksPlugin;

/**
 * Handles operations relation to Class inivitations, including generation and redemption.
 */
@Injectable({ providedIn: 'root' })
export class InviteService {

    constructor(
        private fire: FirebaseService,
        private fns: FirebaseFunctionsService,
        private cameraService: CameraService,
        private ui: UiService
    ) {
        this.mapDecodePupilToken = this.mapDecodePupilToken.bind(this);
        this.mapDecodeClassToken = this.mapDecodeClassToken.bind(this);
        this.mapDecodeToken = this.mapDecodeToken.bind(this);
    }

    public decodeToken<T extends ReadTrackAccessToken>(token: string, type?: 'cat' | 'pat' | 'uat'): T | null {
        try {
            const decode = jwt_decode<T>(token);
            if (type && decode.type !== type) {
                throw new Error('Token does not match specified type');
            }
            return decode;
        } catch (error) {
            this.fire.recordException(error);
            return null;
        }
    }

    /**
     * Maps a Observable containing a inviteToken to the decoded token or if not valid null
     *
     * Intended to be used as a pipe operations.
     *
     * @param source Observable containing token
     */
    public mapDecodePupilToken(source: Observable<string | undefined>): Observable<PupilAccessToken | null> {
        return source.pipe(map(token => (token) ? this.decodeToken<PupilAccessToken>(token, 'pat') : null));
    }
    public mapDecodeClassToken(source: Observable<string | undefined>): Observable<ClassAccessToken | null> {
        return source.pipe(map(token => (token) ? this.decodeToken<ClassAccessToken>(token, 'cat') : null));
    }
    public mapDecodeToken(source: Observable<string | undefined>): Observable<ReadTrackAccessToken | null> {
        return source.pipe(map(token => (token) ? this.decodeToken(token) : null));
    }

    /**
     * Creates a dynamicLink containing an invitation token for the class.
     *
     * @param classid ID of the class for which to create an ivite.
     *
     * @returns A promise with the generated invitation url.
     */
    async createClassInviteUrl(options: { classid: ID, role?: ClassRole, description?: string }): Promise<string> {
        this.fire.logEvent(AnalyticsEvents.create_teacher_invite);
        const { token } = await this.fns.createClassToken(options);
        return await this.createInviteUrl(token);
    }

    /**
     * Creates a dynamicLink containing an invitation token for the pupil as a parent.
     *
     * @param pupilid ID of the pupil for which to create an ivite.
     *
     * @returns A promise with the generated invitation url.
     */
    async createParentInviteUrl(options: { pupilid: ID, name?: string, classname?: string }): Promise<string> {
        this.fire.logEvent(AnalyticsEvents.create_parent_invite);
        const { token } = await this.fns.createPupilToken(options);
        return this.createInviteUrl(token);
    }

    private async createInviteUrl(token: string): Promise<string> {
        try {
            if (Capacitor.platform === 'web') {
                return `${window.origin}/redeem?token=${token}`;
            } else {
                const linkConfiguration: LinkConfig = {
                    ...environment.dynamicLink,
                    uri: `${environment.uri.web}/redeem?token=${token}`
                };
                return (await CapacitorFirebaseDynamicLinks.createDynamicShortLink(linkConfiguration)).value;
            }
        } catch (error) {
            this.fire.recordException(error);
            throw (error);
        }
    }

    /**
     * Redeems an inviate token (for a class) causing the current user to be added to the class with the role
     * specified in the token.
     *
     * The reall work is performed server-side using firebase functions, this function is a facade that calls
     * the relevant firebase function.
     *
     * @param token The invitation token to be redeemed.
     *
     * @returns the reponse from the function
     * TODO - This needs a type definition
     */
    redeemPupilInviteToken(token: string) {
        this.fire.logEvent(AnalyticsEvents.redeem_parent_invite);
        return this.fns.redeemParentInvite({ token });
    }
    redeemPupilInviteCode(code: string) {
        this.fire.logEvent(AnalyticsEvents.redeem_parent_invite);
        return this.fns.redeemParentInvite({ code });
    }
    redeemClassInviteToken(token: string) {
        this.fire.logEvent(AnalyticsEvents.redeem_teacher_invite);
        return this.fns.redeemClassInvite({ token });
    }

    async scanInviteQRCode(): Promise<string | null> {
        let code: string | null = await this.cameraService.scanQRCode();
        if (code) {
            const loading = await this.ui.createLoading('service.invite.message.retrievingToken');
            try {
                loading.present();
                const { token } = await this.fns.retrieveInviteToken({ code });
                await loading.dismiss();
                return token || null;
            } catch (error) {
                await loading.dismiss();
                this.ui.logError(error, 'service.invite.error.tokenRetrieve');
                return null;
            }
        }
        return null;
    }

}
