import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { Pupil } from '@crokerltd/readtrack-shared';
import { AbstractStore } from './abstract-store';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'children', resettable: true })
export class ChildStore extends AbstractStore<Pupil> {

    constructor() {
        super();
    }

}
