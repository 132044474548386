import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthProcessService, FirebaseService } from 'ionic-firebase-auth';

@Injectable({
    providedIn: 'root',
})
export class AuthUIProfilePopoverService {

    constructor(
        private aps: AuthProcessService,
        private popoverController: PopoverController,
        private fire: FirebaseService
    ) {
        this.aps.showProfileDialog.subscribe(async (ev: any) => {
            try {
                await this.showProfile(ev);
            } catch (error) {
                this.fire.recordException(error);
            }
        });
    }

    async showProfile(ev: any): Promise<void> {
        return new Promise<void>(async resolve => {
            const AuthUIUserComponent = await import('ionic-firebase-auth/ionic-auth-ui').then(m => m.AuthUIUserComponent);
            const popover = await this.popoverController.create({
                component: AuthUIUserComponent,
                cssClass: ['wide-popover', 'dark-backdrop'],
                translucent: true
            });
            popover.onDidDismiss().then(
                data => {
                    resolve();
                }
            );
            popover.present();
        });
    }

}
