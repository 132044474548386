import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ID, Pupil } from '@crokerltd/readtrack-shared';
import { RouterQueryHelper } from '../services/router-query.service';
import { PupilQuery } from './pupil.query';
import { PupilService } from './pupil.service';
import { PupilStore } from './pupil.store';
import { AbstractFactoryService, Services } from './abstract-factory';
import { ClassQuery } from './class.query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseFunctionsService } from '../services/firebase-functions.service';
import { FirebaseService } from 'ionic-firebase-auth';
import { AnalyticsEvents } from '../types';

@Injectable({ providedIn: 'root' })
export class PupilFactoryService extends AbstractFactoryService<Pupil, PupilService, PupilQuery> {

    constructor(
        fire: FirebaseService,
        private classQuery: ClassQuery,
        private routerQuery: RouterQueryHelper,
        private fns: FirebaseFunctionsService,
        private angularFireStore: AngularFirestore
    ) {
        super(fire, classQuery.selectActive().pipe(map(cls => cls?.id)), true);
    }

    protected async serviceConstructor(classid: ID): Promise<Services<Pupil, PupilService, PupilQuery>> {
        const store = new PupilStore(classid);
        const query = new PupilQuery(store);
        const service = new PupilService(this.fire, classid, store, this.routerQuery, this.angularFireStore);
        return { store, query, service };
    }

    async getActivePath(): Promise<ID | null> {
        return this.classQuery.getActiveId() || null;
    }

    selectActivePath(): Observable<ID | null> {
        return this.classQuery.selectActiveId().pipe(map(i => i || null));
    }

    async deletePupil(pupil: Pupil) {
        this.fire.logEvent(AnalyticsEvents.delete_pupil, { pupilid: pupil.id, classid: pupil.classid });
        const response = await this.fns.deletePupil({ pupilid: pupil.id, classid: pupil.classid });
        console.log('DELETED > ', response);
        return response;
    }

}
