import { Injectable } from '@angular/core';
import { ClassGroup, ClassRole, ID } from '@crokerltd/readtrack-shared';
import { ClassQuery } from './class.query';
import { ClassService } from './class.service';

@Injectable({ providedIn: 'root' })
export class ActiveClassService {

    constructor(
        private classQuery: ClassQuery,
        private classService: ClassService
    ) {
    }

    updateEntity(updateOrFn: Partial<ClassGroup>): Promise<void> {
        const classid = this.classQuery.requireActiveId();
        return this.classService.updateEntity(classid, updateOrFn);
    }

    async updateTeacherRole(uid: ID, role: ClassRole) {
        const classid = this.classQuery.requireActiveId();
        return this.classService.updateEntity(classid, cls => {
            const teachers = cls?.teachers || {};
            teachers[uid] = { ...teachers[uid], role };
            return { teachers };
        });
    }

    async removeTeacher(uid: ID) {
        const classid = this.classQuery.requireActiveId();
        return this.classService.updateEntity(classid, cls => {
            const teachers = cls?.teachers || {};
            delete teachers[uid];
            return { teachers };
        });
    }



}
