import { Injectable } from '@angular/core';
import { RecordsQuery } from './records.query';
import { RecordsService } from './records.service';
import { RecordsStore } from './records.store';
import { AbstractFactoryService, Services } from './abstract-factory';
import { RouterQueryHelper } from '../services/router-query.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { ReadingRecord } from '@crokerltd/readtrack-shared';
import { PupilFactoryService } from './pupil.factory';
import { ChildQuery } from './child.query';
import { map } from 'rxjs/operators';
import { FirebaseService } from 'ionic-firebase-auth';

@Injectable({ providedIn: 'root' })
export class RecordsFactoryService extends AbstractFactoryService<ReadingRecord, RecordsService, RecordsQuery> {

    constructor(
        fire: FirebaseService,
        private routerQuery: RouterQueryHelper,
        private pfs: PupilFactoryService,
        childQuery: ChildQuery,
        private angularFireStore: AngularFirestore,
    ) {
        super(
            fire,
            combineLatest([pfs.selectAllEntitiesFromAllQueries(), childQuery.selectAll()])
                .pipe(
                    map(([pupils, children]) => ([...pupils, ...children])),
                    map(allKiddies => allKiddies.map(kid => `${kid.classid}/${kid.id}`))
                )
        );
    }

    protected async serviceConstructor(path: string): Promise<Services<ReadingRecord, RecordsService, RecordsQuery>> {
        const [classid, pupilid] = path.split('/');
        /*
        await this.angularFireStore.doc(`/classes/${classid}/pupils/${pupilid}`).snapshotChanges().pipe(
            filter(doc => !doc.payload.metadata.hasPendingWrites),
            take(1)
        ).toPromise();
        */
        const store = new RecordsStore(path);
        const query = new RecordsQuery(store);
        const service = new RecordsService(this.fire, classid, pupilid, store, this.routerQuery, this.angularFireStore);
        return { store, query, service };
    }

    async getActivePath(): Promise<string | null> {
        const pupil = await this.pfs.getActive();
        return (pupil) ? `${pupil.classid}/${pupil.id}` : null;
    }

    selectActivePath(): Observable<string | null> {
        return this.pfs.selectActive().pipe(
            map(pupil => (pupil) ? `${pupil.classid}/${pupil.id}` : null)
        );
    }

}
