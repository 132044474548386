import { Book, ISBN } from '@crokerltd/readtrack-shared';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export async function getOpenLibraryBookInfo(httpClient: HttpClient, isbn: ISBN): Promise<Book | null> {
    const results: { [key: string]: any; }
        = await httpClient.get(`${environment.isbnApi.openLibrary}?bibkeys=ISBN:${isbn}&format=json&jscmd=data`).toPromise();

    if (results.hasOwnProperty('ISBN:' + isbn)) {
        const openlibResult = results['ISBN:' + isbn];
        const result = {
            isbn,
            title: openlibResult.title,
            author: openlibResult.by_statement || (openlibResult.authors)
                ? openlibResult.authors.map((item: any) => item.name).join(', ')
                : undefined,
            cover_url: openlibResult.thumbnail_url || openlibResult.cover?.medium || openlibResult.cover?.small
        };
        return result;
    } else {
        return null;
    }
}

