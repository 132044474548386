import { Pupil, ID } from '@crokerltd/readtrack-shared';
import { PupilStore } from './pupil.store';
import { CollectionConfig } from 'akita-ng-fire';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { RouterQueryHelper, RouterKey } from '../services/router-query.service';
import { AbstractService } from './abstract-service';
import { FirebaseService } from 'ionic-firebase-auth';
import { AnalyticsEvents } from '../types';

@CollectionConfig({ path: 'classes/:classid/pupils' })
export class PupilService extends AbstractService<Pupil> {

    sync(classid: ID): Observable<DocumentChangeAction<Pupil>[]> {
        return this.syncCollection(`classes/${classid}/pupils`);
    }

    constructor(
        fire: FirebaseService,
        readonly classid: ID,
        pupilStore: PupilStore,
        routerQuery: RouterQueryHelper,
        angularFirestore: AngularFirestore
    ) {
        super(fire, pupilStore, angularFirestore);

        this.syncSub = this.syncWithRetry(undefined, classid).subscribe(
            () => { },
            error => {
                this.fire.recordException(error);
            }
        );

        this.activeSub = routerQuery.select(RouterKey.PUPIL).subscribe(pupilid => {
            try {
                this.setActive(pupilid)
            } catch (error) {
                this.fire.recordException(error);
            }
        });
    }

    protected getParents(): { classid: ID } {
        return { classid: this.classid };
    }

    addEntity(pupil: Omit<Pupil, 'id' | 'classid'>): Promise<ID> {
        this.fire.logEvent(AnalyticsEvents.create_pupil);
        const full = {
            ...this.getParents(),
            ...pupil,
        };
        return this.add(full, { params: this.getParents() });
    }

}
