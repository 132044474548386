// Angular
import { Injectable } from '@angular/core';

// Mal
import { AuthProcessService, FirebaseService } from 'ionic-firebase-auth';

// Akita
import { FireAuthService } from 'akita-ng-fire';

// Local
import { ID } from '@crokerltd/readtrack-shared';
import { SessionState, SessionStore } from './session.store';
import { AppUserType } from '../types';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionService extends FireAuthService<SessionState>{

  public readonly userTypeCache$: BehaviorSubject<AppUserType | undefined> = new BehaviorSubject<AppUserType | undefined>(undefined);

  constructor(
    fire: FirebaseService,
    private sessionStore: SessionStore,
    private aps: AuthProcessService
  ) {
    super(sessionStore);
    this.aps.uid$.subscribe(() => {
      try {
        this.reset()
        if (this.userTypeCache$.value) {
          this.setUserType(this.userTypeCache$.value);
        }
      } catch (error) {
        fire.recordException(error)
      }
    });

    this.sync().subscribe(
      () => { },
      error => {
        fire.recordException(error);
      }
    );

  }

  reset() {
    this.sessionStore.reset();
  }

  setLaunchClass(launchClass: ID | null) {
    this.update({ launchClass });
  }

  setUserType(userType?: AppUserType) {
    this.update({ userType });
  }

}
